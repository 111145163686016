import React from "react";
import Root from "./containers/Root";
import "./App.scss";
import { Provider } from 'react-redux';
import store from './store';
import { initializeApp } from '@firebase/app';
import { getAnalytics } from '@firebase/analytics';

const App = () => {
  const firebaseConfig = {
    apiKey: "AIzaSyBAeyzkH6dabNzfS4MCzUd4VmXl_OP1xDA",
    authDomain: "ets2020.firebaseapp.com",
    databaseURL: "https://ets2020.firebaseio.com",
    projectId: "ets2020",
    storageBucket: "ets2020.appspot.com",
    messagingSenderId: "953732907337",
    appId: "1:953732907337:web:1a3e4d2bb20c12708fda98",
    measurementId: "G-L7FLR3CXTY"
  };
  
  // Initialize Firebase
  const app = initializeApp(firebaseConfig);
  const analytics = getAnalytics(app);

  return (
    <Provider store={store}>
      <Root />
    </Provider>
  );
}

export default App;
