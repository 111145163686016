import React, { FC, memo } from 'react';
import { FlatList } from 'react-native';
import styled from 'styled-components/native';
import Fonts from 'theme/fonts';
import Colors from 'theme/colors';
import QuestionItem from './QuestionItem';
import RawHTML from 'components/RawHTML';
import { IoMdOpen } from "react-icons/io";

interface Props {
  data: any;
  part7Index: number;
  isDetail?: boolean;
  onOpenDetail: (data: any, part7Index: number) => void;
  onSelectAnswer: (detailItem: any, questionPosition: number, selected: string, part6Index: number) => void;
  onCollapse: (part6Index: number) => void;
  onCollapseTranslation: (part6Index: number) => void;
}

interface StyledProps {
  height?: number;
  width?: number;
  marginHorizontal?: number;
  paddingHorizontal?: number;
  paddingRight?: number;
  isCorrect?: boolean;
  isSelected?: boolean;
  isShown?: boolean;
}

interface ItemType {
  id: number;
  a: string;
  b: string;
  c: string;
  d: string;
  correct: string;
  question: string;
  selected?: string;
  submitted?: boolean;
}

const ScrollView = styled.ScrollView`
  padding-horizontal: 4px;
`;

const ItemContainer = styled.View<StyledProps>`
  width: 100%;
  background-color: ${Colors.SECOND_COLOR};
`;

const HorizontalView = styled.View`
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const Text = styled(Fonts.Bold)`
  font-size: 18px;
  padding: 8px;
  flex: 1;
`;

const Button = styled.TouchableOpacity`
  padding: 10px;
`;

const TestFlatList = styled(FlatList as new () => FlatList)`
  width: 100%;
  height: 100%;
`;

const ListContainer = styled.View`
  height: 100%;
  width: 100%;  
  padding-vertical: 8px;
`;

const Space = styled.View<StyledProps>`
  background-color: ${Colors.MAIN_COLOR};
  width: 100%;
  height: 1px;
  margin-top: 12px;
`;

const HTMLContainer = styled.View`
  padding-horizontal: 12px;
  padding-vertical: 8px;
`;

const Part7Item: FC<Props> = ({
  data,
  part7Index,
  isDetail,
  onOpenDetail,
  onSelectAnswer,
  onCollapse,
  onCollapseTranslation,
}) => {
  const selectAnswer = (item: any, index: number, selected: string) => {
    onSelectAnswer(item, index, selected, part7Index);
  };

  const openDetail = () => {
    onOpenDetail(data, part7Index);
  };

  const renderItem = ({ index }: { item: ItemType; index: number }) => {
    const item = data.questions[index];
    //1-4: 2
    //5-7: 3
    //8-10: 4
    //11-15: 5
    const LAST_PART6_QUESTION = 146;
    let questionNumber = LAST_PART6_QUESTION + index;
    if (part7Index < 4) { // 2 questions
      questionNumber += part7Index * 2;
    } else if (part7Index < 7) { // 3 questions
      questionNumber += 4 * 2 + (part7Index - 4) * 3;
    } else if (part7Index < 10) { // 4 questions
      questionNumber += 4 * 2 + 3 * 3 + (part7Index - 7) * 4;
    } else { // 5 questions
      questionNumber += 4 * 2 + 3 * 3 + 3 * 4 + (part7Index - 10) * 5;
    }
    return (
      <QuestionItem
        item={item}
        index={index}
        questionNumber={questionNumber + 1}
        onClick={selectAnswer}
      />
    );
  };

  return (
    <ItemContainer>
        <HorizontalView>
          <Text>{`${part7Index + 1}/ ${data.data.title || ""}`}</Text>
          {!isDetail && (
            <Button onPress={openDetail}>
              <IoMdOpen color={Colors.MAIN_COLOR} size={28} />
            </Button>
          )}
        </HorizontalView>
        <Space />
        {!data.isCollapsed && (
          <HTMLContainer>
            <RawHTML>{data.html[0].value}</RawHTML>
          </HTMLContainer>
        )}
        {data.isShownTranslate && <Space />}
        {data.isShownTranslate && !data.isCollapsedTranslation && (
          <HTMLContainer>
            <RawHTML>{data.translate.value}</RawHTML>
          </HTMLContainer> 
        )}

        {data.html.length > 1 && <Space />}
        {data.html.length > 1 && !data.isCollapsed && (
          <HTMLContainer>
            <RawHTML>{data.html[1].value}</RawHTML>
          </HTMLContainer>
        )}
        {data.html.length > 1 && data.isShownTranslate && <Space />}
        {data.html.length > 1 && data.isShownTranslate && !data.isCollapsedTranslation && (
          <HTMLContainer>
            <RawHTML>{data.translate.value1}</RawHTML>
          </HTMLContainer> 
        )}

        {data.html.length > 2 && <Space />}  
        {data.html.length > 2 && !data.isCollapsed && (
          <HTMLContainer>
            <RawHTML>{data.html[2].value}</RawHTML>
          </HTMLContainer>
        )}
        {data.html.length > 2 && data.isShownTranslate && <Space />}
        {data.html.length > 2 && data.isShownTranslate && !data.isCollapsedTranslation && (
          <HTMLContainer>
            <RawHTML>{data.translate.value2}</RawHTML>
          </HTMLContainer> 
        )}

        <Space />
        <ListContainer>
          <TestFlatList
            data={data.questions}
            renderItem={renderItem}
            keyExtractor={(item, index) => `${index}_${item.question}`}
          />
        </ListContainer>
    </ItemContainer>
  );
};

export default memo(Part7Item);
