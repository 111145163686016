export const scrollToTop = () => {
  window.scrollTo(0, 0)
};

export const scrollToBottom = () => {
  window.scrollTo(0, document.body.scrollHeight);
};

export const onScroll = (callback) => {
  window.onscroll = () => {
    callback(window.pageYOffset, document.body.scrollHeight);
  };
}