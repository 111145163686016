import Screen from '../Screen';
import React, { FC, useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import textAPI from "api/textAPI";
import CryptoJS from 'crypto-js';
import Part3Item from './shared/Part3Item';
import Footer from 'components/Footer';
import { getPart } from 'store/selectors/selectors';
import { updatePartAction } from 'store/actionTypes/actionType';
import { useDispatch, useSelector } from 'react-redux';
import { TestFlatList } from 'components/TestFlatList';

const Part3: FC = () => {
  const [testList, setTestList] = useState<any[]>([]);
  const [correctNumber, setCorrectNumber] = useState<number>(-1);
  const [totalNumber, setTotalNumber] = useState<number>(-1);

  const history = useHistory();
  const dispatch = useDispatch();
  const { testNumber, year } = useParams();
  const testName = `test${testNumber}`;
  const part  = useSelector(getPart(testName, 'part3'));
  useEffect(() => {
    getData(testNumber, part);
  }, []);

  const getData = async (testNumber: number, part: any) => {
    const itemTypes: any[] = (part && part.questions && part.questions.length > 0) ? part.questions : [];
    if (itemTypes.length > 0) {
      setTestList(itemTypes);
    } else {
      const data = await textAPI.get(testNumber, 3);
      const plainData = CryptoJS.AES.decrypt(data, 'thieumao').toString(CryptoJS.enc.Utf8);
      const json = JSON.parse(plainData);
      setTestList(json);
      updateDatabase(testName, json);
    }
  };

  const updateDatabase = async (testName: string, partQuestions: any[]) => {
    const partDetail = {
      name: 'Part 3',
      questions: partQuestions,
    };
    dispatch(updatePartAction(testName, 'part3', partDetail));
  };

  const onOpenDetail = (data: any, part3Index: number, testNumber: number) => {
    history.push(`/toeic${year || '2020'}/test${testNumber}/part3/question${part3Index + 1}`);
  };

  const onSelectAnswer = (detailItem: any, questionPosition: number, selected: string, part3Index: number) => {
    let arr = testList || [];
    if (arr[part3Index].questions[questionPosition].selected != selected) {
      arr[part3Index].questions[questionPosition].selected = selected;
    } else {
      arr[part3Index].questions[questionPosition].selected = undefined;
    }
    updateDatabase(testName, arr);
  };

  const onReset = () => {
    let arr = testList || [];
    for (let test of arr) {
      test.submitted = false;
      test.isShownTranslate = false;
      test.isShownTranscript = false;
      const questions = (test.questions) ? test.questions : [];
      for (const question of questions) {
        const item = question;
        if (item) {
          item.submitted = false;
          item.selected = false;
        }
      }
    }
    updateDatabase(testName, arr);

    setCorrectNumber(-1);
  };

  const onSubmit = () => {
    calculateScore();
    let arr = testList || [];
    for (let test of arr) {
      const questions = (test.questions) ? test.questions : [];
      for (const question of questions) {
        const item = question;
        if (item && item.selected) {
          test.submitted = true;
          test.isShownTranslate = true;
          test.isShownTranscript = true;
          item.submitted = true;
        }
      }
    }
    updateDatabase(testName, arr);
  };

  const calculateScore = () => {
    let correctCount = 0;
    let count = 0;
    for (const test of testList) {
      const questions = (test.questions) ? test.questions : []; 
      for (const question of questions) {
        count += 1;
        const item = question;
        if (item && item.selected) {
          if (item.selected == item.correct) {
            correctCount += 1;
          } else {
          }
        } else {
        }
      }
    }
    setCorrectNumber(correctCount);
    setTotalNumber(count);
  };

  const renderItem = ({ index }: { item: any; index: number }) => {
    const item = testList[index];
    return (
      <Part3Item
        data={item}
        isShownTranslate={item.isShownTranslate || false}
        part3Index={index}
        testNumber={testNumber}
        onOpenDetail={onOpenDetail}
        onSelectAnswer={onSelectAnswer}
        onCollapse={() => null}
        onCollapseTranslation={() => null}
      />
    );
  };

  return (
      <Screen canBack title={`Test ${testNumber} - Part 3`} showMenu={false} showScrollToTop showScrollToBottom isLoading={!testList.length}>
        <TestFlatList
          data={testList}
          renderItem={renderItem}
          keyExtractor={(item, index) => `${index}_${item}`}
        />
        <Footer correctNumber={correctNumber} totalNumber={totalNumber} onReset={onReset} onSubmit={onSubmit} />
      </Screen>
  );
};

export default Part3;
