import axiosClient from './axiosClient.js'

//https://raw.githubusercontent.com/QuizSystem/ETS2020Texts/master/part5/part5-test1.json
const textAPI = {
  get: (test, part) => {
    const url = `/ETS2020Texts/master/part${part}/part${part}-test${test}.json`;
    return axiosClient.get(url);
  },
}

export default textAPI;