import { convertNumberToString } from './convertNumberToString';

export const formatMoney = (n: number) => String(n).replace(/(.)(?=(\d{3})+$)/g, '$1,');

export const isEmpty = (str?: string) => (!str || str.length === 0);

export const removeSpecial = (str: string) => str.replace(/[^\w\s]/gi, "");

export const isCorrect = (str1: string, str2: string) => {
  if (str1 === str2) {
    return true;
  }
  if (str1.toLowerCase().trim() === str2.toLowerCase().trim()) {
    return true;
  }
  // check has special characters
  const format = /[ `@#$%^&*()+\-=\[\]{}\\|<>\/?~]/;
  if (format.test(str1)) {
    return false;
  }
  if (format.test(str2)) {
    return false;
  }
  // remove special characters
  const newStr1 = str1.replace(/[^a-zA-Z0-9']/g, "").toLowerCase().trim();
  const newStr2 = str2.replace(/[^a-zA-Z0-9']/g, "").toLowerCase().trim();
  if (newStr1 === newStr2) {
    return true;
  }
  // check number
  if (Number(newStr1) || Number(newStr2)) {
    const numberStr1 = Number(newStr1) ? convertNumberToString(Number(newStr1))  : `${Number(newStr1)}`;
    const numberStr2 = Number(newStr2) ? convertNumberToString(Number(newStr2))  : `${Number(newStr2)}`;
    if (numberStr1.toLowerCase().trim() === numberStr2.toLowerCase().trim()) {
      return true;
    }
  }

  return false;
}

export const convertHTMLToPlainText = (html) => {
  return html.replace(/<[^>]*>/g, '')
  .replaceAll("&rsquo;", "'")
  .replaceAll("&hellip;", "...")
  .replaceAll("&nbsp;", " ")
  .replaceAll("&mdash;", "-")
  .replaceAll("&ndash;", "-")
  .replaceAll("&ldquo;", '"')
  .replaceAll("&rdquo;", '"')
  .replaceAll("&amp;", "&")
  .replaceAll(" ", " ")
  .replaceAll("’", "'")
  .trim();
}
