import Screen from '../Screen';
import Part1 from '../Part1';
import Part2 from '../Part2';
import Part3 from '../Part3';
import Part4 from '../Part4';
import Part5 from '../Part5';
import Part6 from '../Part6';
import Part7 from '../Part7';
import React, { FC } from 'react';
import { useHistory, useParams } from 'react-router-dom';

const Part: FC = () => {
  const { testNumber, partNumber } = useParams();
  console.log('testNumber = ', testNumber);
  console.log('partNumber = ', partNumber);
  
  switch(Number(partNumber)) { 
    case 1: return (<Part1 />);
    case 2: return (<Part2 />);
    case 3: return (<Part3 />);
    case 4: return (<Part4 />);
    case 5: return (<Part5 />);
    case 6: return (<Part6 />);
    case 7: return (<Part7 />);
    default: return (<div />); 
  };
};

export default Part;
