import Screen from '../Screen';
import React, { FC, useState } from 'react';
import styled from 'styled-components/native';
import Fonts from 'theme/fonts';
import Colors from 'theme/colors';
import { useHistory, useParams } from 'react-router-dom';
import { TestFlatList } from 'components/TestFlatList';
import useWindowDimensions from 'hooks/useWindowDimensions';
import { openLinkNewTab } from 'utils/direct';

interface StyledProps {
  size?: number;
  width?: number;
  height?: number;
  marginHorizontal?: number;
  paddingHorizontal?: number;
  paddingRight?: number;
  isCorrect?: boolean;
  isSelected?: boolean;
  isShown?: boolean;
}


const ButtonText = styled(Fonts.Bold)`
  font-size: 18px;
  background-color: ${Colors.BACKGROUND_COLOR};
  margin: 10px;
  padding: 10px;
`;

const Button = styled.TouchableOpacity`
`;

const Image = styled.Image<StyledProps>`
  width: ${props => props.width}px;
  height: ${props => props.height}px;
  resize-mode: contain;
  align-self: center;
`;

const Description = styled(Fonts.Italic)`
  font-size: 18px;
  margin: 10px;
  padding: 10px;
  align-self: center;
`;

const TextLink = styled(Fonts.Bold)`
  font-size: 18px;
  margin: 10px;
  padding: 10px;
  align-self: center;
  text-align: center;
  color: ${Colors.MAIN_COLOR};
  text-decoration: underline;
`;

const HorizontalView = styled.View`
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

export const EmptyView = styled.View`
  width: 4%;
`;

const TestList: FC = () => {
  const googlePlayPath = `https://raw.githubusercontent.com/QuizSystem/ETS2020Images/master/Images/googleplay.png`;
  const appStorePath = `https://raw.githubusercontent.com/QuizSystem/ETS2020Images/master/Images/appstore.png`;

  const history = useHistory();
  const { year } = useParams();

  const [testList] = useState<string[]>([
    'Test 1', 'Test 2', 'Test 3', 'Test 4', 'Test 5', 
    'Test 6', 'Test 7', 'Test 8', 'Test 9', 'Test 10',
  ]);


  const [testList2021] = useState<string[]>([
    'Test 1', 'Test 2', 'Test 3', 'Test 4', 'Test 5'
  ]);

  const openPartListScreen = (index: number) => {
    const testNumber = index + 1;
    history.push(`/toeic${year || '2020'}/test${testNumber}`);
  };

  const renderItem = ({ index }: { item: string; index: number }) => {
    return (
      <ButtonText onPress={() => openPartListScreen(index)}>
        {year == 2021 ? testList2021[index] : testList[index]}
      </ButtonText>
    );
  };

  const openGooglePlay = () => openLinkNewTab('https://play.google.com/store/apps/details?id=com.tm.ets2020');

  const openAppStore = () => openLinkNewTab('https://apps.apple.com/vn/app/ets2020/id1536255574');

  const openTOEIC2021 = () => openLinkNewTab('http://2021.language123.net/');

  const openTestList = () => {
    history.push(`/toeic${year === '2021' ? 2020 : 2021}`);
  }

  const { width, height } = useWindowDimensions();
  let size = width > height ? height : width;
  if (size > 500) {
    size = 500;
  }
  return (
    <Screen title={`TOEIC ${year || '2020'}`}>
      <TestFlatList
        data={year == 2021 ? testList2021 : testList}
        renderItem={renderItem}
        keyExtractor={(item, index) => `${index}_${item}`}
      />
      <Button onPress={() => openTestList()}>
        <TextLink>{`TOEIC ${year === '2021' ? '2020' : '2021'}`}</TextLink>
      </Button>
      {/* <Description>{`There are 200 questions in each test.`}</Description> */}
      <HorizontalView>
        <Button onPress={() => openGooglePlay()}>
          <Image source={{ uri: googlePlayPath }} width={size / 3} height={size / 6} />
        </Button>
        {/* <EmptyView /> */}
        {/* <Button onPress={() => openAppStore()}>
          <Image source={{ uri: appStorePath }} width={size / 3} height={size / 6} />
        </Button> */}
      </HorizontalView>
    </Screen>
  );
};

export default TestList;
