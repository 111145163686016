import React, { FC, memo } from 'react';
import styled from 'styled-components/native';

interface Props {
  maxWidth?: string;
  value?: string;
  placeholder?: string;
  onChangeText?: (text) => void;
}

const StyledInput = styled.TextInput<Props>`
  padding: 4px;
  height: 32px;
  margin-horizontal: 4px;
  font-size: 16px;
  max-width: ${props => props.maxWidth};
`;

const Input: FC<Props> = ({ maxWidth, value, placeholder, onChangeText, ...rest }) => {
  return(
    <StyledInput
      maxWidth={maxWidth}
      value={value}
      placeholder={placeholder}
      onChangeText={onChangeText}
      defaultValue=""
      clearButtonMode="always"
      {...rest}
    />
  );
}

Input.defaultProps = {
  maxWidth: '24%',
};

export default memo(Input);