import Screen from '../Screen';
import Part1DetailFill from '../Part1DetailFill';
import Part2DetailFill from '../Part2DetailFill';
import React, { FC } from 'react';
import { useHistory, useParams } from 'react-router-dom';

const PartDetailFill: FC = () => {
  const { testNumber, partNumber, questionNumber } = useParams();
  console.log('testNumber = ', testNumber);
  console.log('partNumber = ', partNumber);
  console.log('questionNumber = ', questionNumber);

  if (!Number(questionNumber)) {
    return (<div />);
  }
  
  switch(Number(partNumber)) { 
    case 1: return (<Part1DetailFill />);
    case 2: return (<Part2DetailFill />);
    default: return (<div />); 
  };
};

export default PartDetailFill;
