import React, { FC, memo, forwardRef } from 'react';
import styled from 'styled-components/native';
import Colors from 'theme/colors';

interface Props {
  text: string,
}

const Container = styled.View`
  width: 100%;
  align-items: flex-start;
`;

const TextCountDown = styled.Text`
  color: ${Colors.COUNTDOWN_COLOR};
`;

const TimeText: FC<Props> = forwardRef(({ text }, ref) => { 

  return (
    <Container>
      <TextCountDown>{text}</TextCountDown>
    </Container>
  );
});

TimeText.defaultProps = {
  text: ''
};

export default memo(TimeText);
