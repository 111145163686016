const shades = {
  TRANSPARENT: 'transparent',
  WHITE: '#FFFFFF',
  BLACK: '#000000',
  PURPLE: '#3A1145',
  SILVER: '#BDBDBD',
  SILVER2: '#CCC',
  ORANGE: '#E88200',
  LIGHT_GREEN: '#C2E000',
  DARK_GREEN: '#A2C927',
  MINE_SHAFT: '#323232',
  MINE_SHAFT2: '#303030',
  YUKON_GOLD: '#666804',
  CONFETTI: '#EAEB5E',
  CURIOUS_BLUE: '#2F95DC',
  CURIOUS_BLUE2: '#158ECB',
  CORNFLOWER_BLUE: '#6195ED',
  CERULEAN: '#0C8AC9',
  TUNDORA: '#4D4D4D',
  MALIBU: '#59D7FF',
  GRAY: '#888888',
  SILVER_CHALICE: '#A3A3A3',
  DENIM: '#1489C8',
  DENIM2: '#1382BE',
  SCORPION: '#585858',
  JAZZBERRY_JAM: '#A51276',
  OBSERVATORY: '#03867F',
  BUDDHA_GOLD: '#CD9A00',
  DOVE_GRAY: '#666666',
  DODGER_BLUE: '#1A9FFF',
  SCARLET: '#FF2F00',
  PISTACHIO: '#9ACD00',
  INDIGO: '#5C6BC1',
  HOLLYWOOD_CERISE: '#E00087',
  KEPPEL: '#3EAEAD',
  DEEP_BLUSH: '#E05CA9',
  ELECTRIC_VIOLET: '#9A00CD',
  BITTER_SWEET: '#FF6A65',
  SPRING_GREEN: '#00FCB2',
  CYAN_AQUA: '#19EDFF',
  EASTERN_BLUE: '#1984BD',
  REGENT_ST_BLUE: '#ABD2E7',
  HARLEQUIN: '#30CD00',
  RED_ORANGE: '#FF2F2F',
  GALLERY: '#ECECEC',
  CREAM: '#FFFFCD',
  OUTRAGEOUS_ORANGE: '#FF6633',
  CONCRETE: '#F2F2F2',
  MERCURY: '#E9E9E9',
  MERCURY2: '#E3E3E3',
  DUSTY_GRAY: '#9C9C9C',
  MONA_LISA: '#FF9A9A',
  FOUNTAIN_BLUE: '#5AC7C6',
  ALTO: '#DADADA',
  LOCHMARA: '#0B83BF',
  SCIENCE_BLUE: '#0366D6',
  REEF: '#CCFF99',
  YOUR_PINK: '#FFCCCC',
  RAJAH: '#F9C66B',
  CHICAGO: '#5A5A58',
  LINK_WATER: '#E1EFF7',
  PEPPER_MINT: '#D2F0D2',
  BLIZZARD_BLUE: '#A4D3EB',
  BOULDER: '#777',
  GRAY_NICKEL: '#C5C5C4',
  ANAKIWA: '#8EE7FC',
  EMERALD: '#5DCD5D',
  RED: '#FF0000',
  BLUE: '#0000FF',
  GREEN: '#00FF00',
  YELLOW: '#FFFF00',
  CORRECT: '#f5ea89',
  SUVA_GRAY: '#848283',
};

const common = {
  TINT_COLOR: shades.CURIOUS_BLUE,
  MAIN_COLOR: '#3A5997',
  MAIN_COLOR_2: '#4F5F9F',
  TEXT_COLOR: '#000000',
  BACKGROUND_COLOR: '#FFFFFF',
  SECOND_COLOR: '#EBEDF0',
  LINE_COLOR: '#EAEAEA',
};

const applied = {
  TAB_ICON_DEFAULT: shades.SILVER2,
  TAB_ICON_SELECTED: common.TINT_COLOR,
  TAB_BAR: shades.WHITE,
  ERROR_BACKGROUND: shades.RED,
  ERROR_TEXT: shades.WHITE,
  WARNING_BACKGROUND: shades.CONFETTI,
  WARNING_TEXT: shades.YUKON_GOLD,
  NOTICE_BACKGROUND: common.TINT_COLOR,
  NOTICE_TEXT: shades.WHITE,
  TEXT: shades.MINE_SHAFT,
  TEXT_HEADER: shades.TUNDORA,
  DIVIDER: shades.MERCURY,
  COUNTDOWN_COLOR: shades.SUVA_GRAY
};

export default {
  ...shades,
  ...common,
  ...applied,
};
