export enum Answer {
    A = 'a',
    B = 'b',
    C = 'c',
    D = 'd',
}

export enum Choose {
    CORRECT = 'correct',
    INCORRECT = 'incorrect',
    NONE = 'none',
}
