import Screen from '../Screen';
import Part4DetailTyping from '../Part4DetailTyping';
import React, { FC } from 'react';
import { useHistory, useParams } from 'react-router-dom';

const PartDetailTyping: FC = () => {
  const { testNumber, partNumber, questionNumber } = useParams();
  console.log('testNumber = ', testNumber);
  console.log('partNumber = ', partNumber);
  console.log('questionNumber = ', questionNumber);

  if (!Number(questionNumber)) {
    return (<div />);
  }
  
  switch(Number(partNumber)) { 
    case 4: return (<Part4DetailTyping />);
    default: return (<div />); 
  };
};

export default PartDetailTyping;
