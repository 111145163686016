import Screen from '../Screen';
import React, { FC, useState } from 'react';
import styled from 'styled-components/native';
import Fonts from 'theme/fonts';
import Colors from 'theme/colors';
import { useParams, useHistory } from 'react-router-dom';
import { TestFlatList } from 'components/TestFlatList';
import AdSense from 'react-adsense';
import useWindowDimensions from "hooks/useWindowDimensions";

const Section1 = styled(Fonts.Bold)`
  color: ${Colors.MAIN_COLOR};
  text-align: left;
  font-size: 20px;
  padding-horizontal: 8px;
  margin-bottom: 10px;
  margin-top: 4px;
`;

const Section2 = styled(Fonts.Bold)`
  color: ${Colors.MAIN_COLOR};
  text-align: left;
  font-size: 20px;
  padding-horizontal: 8px;
  margin-bottom: 10px;
  margin-top: 12px;
`;

const HorizontalView = styled.View`
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: ${Colors.BACKGROUND_COLOR};
  margin: 10px;
  padding: 10px;
`;

const ButtonText = styled(Fonts.Bold)`
  font-size: 18px;
  flex: 1;
`;

const QuestionNumberText = styled(Fonts.Italic)`
  font-size: 18px;
`;

const ItemContainer = styled.View``;

const Description = styled(Fonts.Italic)`
  font-size: 18px;
  margin: 10px;
  padding: 10px;
  align-self: center;
`;

const PartList: FC = () => {
  const { testNumber, year } = useParams();
  const history = useHistory();
  const { width, height } = useWindowDimensions();

  // const [testList] = useState<string[]>([
  //   'Part 1 - Photographs', 
  //   'Part 2 - Question & Response', 
  //   'Part 3 - Conversations', 
  //   'Part 4 - Short Talks', 
  //   'Part 5 - Incomplete Sentences', 
  //   'Part 6 - Text Completion', 
  //   'Part 7 - Reading Comprehension'
  // ]);

  const [testList] = useState<string[]>([
    'Part 1', 
    'Part 2', 
    'Part 3', 
    'Part 4', 
    'Part 5', 
    'Part 6', 
    'Part 7'
  ]);

  const [questionNumbers] = useState<string[]>([
    '6 questions', 
    '25 questions', 
    '13 conversations',
    '10 talks',
    '30 questions',
    '4 passages', 
    '15 articles',
  ]);

  const [testList2021] = useState<string[]>(['Part 3', 'Part 4']);

  const openPartScreen = (index: number) => {
    const plus = year === '2020' ? 1 : 3;
    const partNumber = index + plus;
    history.push(`/toeic${year || '2020'}/test${testNumber}/part${partNumber}`);
  };

  const renderItem = ({ index }: { item: string; index: number }) => {
    return (
      <ItemContainer>
        {/* {index == 0 && <Section1>{`Section 1 - Listening`}</Section1>}
        {index == 4 && <Section2>{`Section 2 - Reading`}</Section2>} */}
        <HorizontalView>
          <ButtonText onPress={() => openPartScreen(index)}>
            {year == 2021 ? testList2021[index] : testList[index]}
          </ButtonText>
    
          <QuestionNumberText>
            {/* {width >= 520 ? `${questionNumbers[index]}` : ''} */}
          </QuestionNumberText>
        </HorizontalView>
      </ItemContainer>
    );
  };

  return (
    <Screen canBack title={`Test ${testNumber} - TOEIC ${year || '2020'}`}>
      <TestFlatList
        data={year == 2021 ? testList2021 : testList}
        renderItem={renderItem}
        keyExtractor={(item, index) => `${index}_${item}`}
      />
      {/* <Description>{`There are 100 questions in each section.`}</Description> */}
      <AdSense.Google
        client='ca-pub-2534229011090838'
        slot='6432932865'
        style={{ marginTop: 24, width: '80%', minHeight: 100, maxHeight: 500, alignSelf: 'center', alignItems: 'center', justifyContent: 'center' }}
      />
    </Screen>
  );
};


export default PartList;
