import React from "react";
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import TestList from "containers/TestList";
import PartList from "containers/PartList";
import Part from "containers/Part";
import PartDetail from "containers/PartDetail";
import PartDetailFill from "containers/PartDetailFill";
import PartDetailTyping from "containers/PartDetailTyping";

const Root = () => {
  return (
    <Router>
      <Switch>
        <Route path="/toeic:year/test:testNumber/part:partNumber/question:questionNumber/typing">
          <PartDetailTyping />
        </Route>
        <Route path="/toeic:year/test:testNumber/part:partNumber/question:questionNumber/fill">
          <PartDetailFill />
        </Route>
        <Route path="/toeic:year/test:testNumber/part:partNumber/question:questionNumber">
          <PartDetail />
        </Route>
        <Route path="/toeic:year/test:testNumber/part:partNumber">
          <Part />
        </Route>
        <Route path="/toeic:year/test:testNumber">
          <PartList />
        </Route>
        <Route path="/toeic:year">
          <TestList />
        </Route>

        <Route path="/">
          <TestList />
        </Route>
      </Switch>
    </Router >
  );
};

export default Root;
