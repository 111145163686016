import React, { FC, memo } from 'react';
import styled from 'styled-components/native';
import Colors from 'theme/colors';
import { IoMdVolumeHigh } from 'react-icons/io';
import { useSpeechSynthesis } from 'react-speech-kit';

interface Props {
  text?: string;
}

const Container = styled.View`
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-self: center;
`;

const Button = styled.TouchableOpacity`
  padding: 10px;
`;

const TextToSpeech: FC<Props> = ({
  text
}) => {
  const { speak } = useSpeechSynthesis();

  const onPress = () => {
    speak({ text: text || '' });
  };

  return (
    <Container>
      <Button onPress={onPress}>
        <IoMdVolumeHigh color={Colors.GRAY} size={16} />
      </Button>
    </Container>
  );
};

export default memo(TextToSpeech);
