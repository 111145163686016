import React, { FC, memo } from 'react';
import styled from 'styled-components/native';
import Colors from 'theme/colors';
import Fonts from 'theme/fonts';
import { IoMdArrowBack, IoIosArrowBack, IoIosArrowForward } from 'react-icons/io';

interface Props {
  title?: string;
  onBack?: () => void;
  doneTitle?: string;
  canBack?: boolean;
  showArrow?: boolean;
  disableBack?: boolean;
  disableForward?: boolean;
  onBackDetail?: () => void;
  onForwardDetail?: () => void;
}

const Container = styled.View`
  background-color: ${Colors.MAIN_COLOR};
  height: 60px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-horizontal: 1%;
`;

const EmptyView = styled.View`
  width: 28px;
  height: 28px;
`;

const ArrowContainView = styled.View`
  height: 28px;
  align-items: center;
  justify-content: center;
  flex-direction: row;
`;

const ArrowButton = styled.TouchableOpacity`
  padding: 4px;
`;

const Button = styled.TouchableOpacity`
  padding: 10px;
`;

const Title = styled(Fonts.Title1)`
  font-weight: bold;
  color: ${Colors.WHITE};
`;

const HeaderWithBackForward: FC<Props> = ({
  title,
  onBack,
  canBack = false,
  showArrow = false,
  disableBack = false,
  disableForward = false,
  onBackDetail,
  onForwardDetail,
}) => {
  return (
    <Container>
      {canBack ? (
        <Button onPress={onBack}>
          <IoMdArrowBack color={Colors.WHITE} size={28} />
        </Button>
      ) : (
        <EmptyView />
      )
      }
      <Title>{title}</Title>
      {showArrow ? (
        <ArrowContainView>
          { !disableBack ? (
            <ArrowButton onPress={onBackDetail}>
              <IoIosArrowBack color={Colors.WHITE} size={28} />
            </ArrowButton>
          ) : (
            <ArrowButton onPress={() => null}>
              <IoIosArrowBack color={Colors.MAIN_COLOR_2} size={28} />
            </ArrowButton>
          )}
          { !disableForward ? (
            <ArrowButton onPress={onForwardDetail}>
              <IoIosArrowForward color={Colors.WHITE} size={28} />
            </ArrowButton>
          ) : (
            <ArrowButton onPress={() => null}>
              <IoIosArrowForward color={Colors.MAIN_COLOR_2} size={28} />
            </ArrowButton>
          )}
        </ArrowContainView>
      ) : (
        <EmptyView />
      )
      }
    </Container>
  );
};

export default memo(HeaderWithBackForward);
