import React, { FC, memo } from 'react';
import styled from 'styled-components/native';
import Colors from 'theme/colors';
import Fonts from 'theme/fonts';

interface Props {
  maxWidth?: string;
  value?: string;
  placeholder?: string;
  onChangeText?: (text) => void;
}

const View = styled.View`
  margin-vertical: 8px;
`;

const Text = styled.Text<Props>`
  padding: 12px;
  font-size: 18px;
  max-width: ${props => props.maxWidth};
  min-height: 200px;
  border-width: 1px;
  border-color: green;
  border-radius: 8px;
  position: absolute;
  width: 100%;
  height: 100%;
  font-weight: bold;
`;

const StyledInputBelow = styled.TextInput<Props>`
  padding: 12px;
  font-size: 18px;
  max-width: ${props => props.maxWidth};
  min-height: 200px;
  border-width: 1px;
  border-color: green;
  border-radius: 8px;
  font-weight: bold;
`;

const StyledInput = styled.TextInput<Props>`
  padding: 12px;
  font-size: 18px;
  max-width: ${props => props.maxWidth};
  min-height: 200px;
  border-width: 1px;
  border-color: green;
  border-radius: 8px;
  position: absolute;
  width: 100%;
  height: 100%;
  color: ${Colors.MAIN_COLOR};
  font-weight: bold;
`;

const InputMultiLine: FC<Props> = ({ maxWidth, value, placeholder, onChangeText, ...rest }) => {
  return(
    <View>
      {/* <Text>{placeholder}</Text> */}
      <StyledInputBelow
        maxWidth={maxWidth}
        placeholder={placeholder}
        defaultValue=""
        clearButtonMode="always"
        multiline
        {...rest}
      />
      <StyledInput
        maxWidth={maxWidth}
        value={value}
        placeholder={placeholder}
        onChangeText={onChangeText}
        defaultValue=""
        clearButtonMode="always"
        multiline
        {...rest}
      />
    </View>
  );
}

InputMultiLine.defaultProps = {
  maxWidth: '100%',
};

export default memo(InputMultiLine);