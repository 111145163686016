import Screen from '../Screen';
import Part1Detail from '../Part1Detail';
import Part2Detail from '../Part2Detail';
import Part3Detail from '../Part3Detail';
import Part4Detail from '../Part4Detail';
import Part5Detail from '../Part5Detail';
import Part6Detail from '../Part6Detail';
import Part7Detail from '../Part7Detail';
import React, { FC } from 'react';
import { useHistory, useParams } from 'react-router-dom';

const PartDetail: FC = () => {
  const { testNumber, partNumber } = useParams();
  console.log('testNumber = ', testNumber);
  console.log('partNumber = ', partNumber);
  
  switch(Number(partNumber)) { 
    case 1: return (<Part1Detail />);
    case 2: return (<Part2Detail />);
    case 3: return (<Part3Detail />);
    case 4: return (<Part4Detail />);
    case 5: return (<Part5Detail />);
    case 6: return (<Part6Detail />);
    case 7: return (<Part7Detail />);
    default: return (<div />); 
  };
};

export default PartDetail;
