import Screen from "../Screen";
import React, { FC, useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import Part6Item from "containers/Part6/shared/Part6Item";
import { useDispatch, useSelector } from "react-redux";
import textAPI from "api/textAPI";
import CryptoJS from "crypto-js";
import { updatePartAction } from "store/actionTypes/actionType";
import { getPart } from "store/selectors/selectors";
import Footer from "components/Footer";
import styled from "styled-components/native";
import { scrollToTop } from 'utils/scroll';
import useEventListener from '@use-it/event-listener';

const Container = styled.View`
  padding-horizontal: 1%;
`;

const Part6Detail: FC = () => {
  const [testList, setTestList] = useState<any[]>([]);
  const [correctNumber, setCorrectNumber] = useState<number>(-1);
  const [totalNumber, setTotalNumber] = useState<number>(-1);

  const history = useHistory();
  const dispatch = useDispatch();
  const { testNumber, questionNumber, year } = useParams();
  const testName = `test${testNumber}`;
  const part = useSelector(getPart(testName, "part6"));

  useEffect(() => {
    getData(testNumber, part);
    scrollToTop();
  }, []);

  const getData = async (testNumber: number, part: any) => {
    const itemTypes: any[] =
      part && part.questions && part.questions.length > 0 ? part.questions : [];
    if (itemTypes.length > 0) {
      setTestList(itemTypes);
      console.log(testList);
    } else {
      const data = await textAPI.get(testNumber, 6);
      const plainData = CryptoJS.AES.decrypt(data, "thieumao").toString(
        CryptoJS.enc.Utf8
      );
      const json = JSON.parse(plainData);
      setTestList(json);
      updateDatabase(testName, json);
    }
  };

  const updateDatabase = async (testName: string, partQuestions: any[]) => {
    const partDetail = {
      name: "Part 6",
      questions: partQuestions,
    };
    dispatch(updatePartAction(testName, "part6", partDetail));
  };

  const onSelectAnswer = (
    detailItem: any,
    questionPosition: number,
    selected: string,
    part6Index: number
  ) => {
    let arr = testList || [];
    if (arr[part6Index].questions[questionPosition].selected != selected) {
      arr[part6Index].questions[questionPosition].selected = selected;
    } else {
      arr[part6Index].questions[questionPosition].selected = undefined;
    }
    updateDatabase(testName, arr);
  };

  const onReset = () => {
    let arr = testList || [];
    for (let test of arr) {
      test.submitted = false;
      test.isShownTranslate = false;
      const questions = test.questions ? test.questions : [];
      for (const question of questions) {
        const item = question;
        if (item) {
          item.submitted = false;
          item.selected = false;
        }
      }
    }
    updateDatabase(testName, arr);

    setCorrectNumber(-1);
  };

  const onSubmit = () => {
    calculateScore();
    let arr = testList || [];
    for (let test of arr) {
      const questions = test.questions ? test.questions : [];
      for (const question of questions) {
        const item = question;
        if (item && item.selected) {
          test.submitted = true;
          test.isShownTranslate = true;
          item.submitted = true;
        }
      }
    }
    updateDatabase(testName, arr);
  };

  const calculateScore = () => {
    let correctCount = 0;
    let count = 0;
    for (const test of testList) {
      const questions = test.questions ? test.questions : [];
      for (const question of questions) {
        count += 1;
        const item = question;
        if (item && item.selected) {
          if (item.selected == item.correct) {
            correctCount += 1;
          } else {
          }
        } else {
        }
      }
    }
    setCorrectNumber(correctCount);
    setTotalNumber(count);
  };

  const MAX = 4;
  const PART = 6;

  const onBackDetail = () => {
    if (questionNumber > 1 && questionNumber <= MAX) {
      history.replace(`/toeic${year || '2020'}/test${testNumber}/part${PART}/question${+questionNumber - 1}`);
    }
  };

  const onForwardDetail = () => {
    if (questionNumber >= 1 && questionNumber < MAX) {
      history.replace(`/toeic${year || '2020'}/test${testNumber}/part${PART}/question${+questionNumber + 1}`);
    }
  };

  const handler = ({ key }) => {
    if (key.length === 1) {
      if (key === '<' || key === ',') {
        onBackDetail();
      } else if (key === '>' || key === '.') {
        onForwardDetail();
      }
    } else if (key === 'ArrowLeft') {
      onBackDetail();
    } else if (key === 'ArrowRight') {
      onForwardDetail();
    }
  }

  useEventListener('keydown', handler);

  const title = `Question ${questionNumber} / ${MAX}`;

  if (testList.length >= questionNumber) {
    const index = questionNumber - 1;
    const item = testList[index];
    return (
      <Screen canBack title={title} showArrow={true} onBackDetail={onBackDetail} onForwardDetail={onForwardDetail} disableBack={questionNumber === 1} disableForward={questionNumber === MAX} showScrollToTop showScrollToBottom>
        <Container>
          <Part6Item
            data={item}
            part6Index={index}
            onOpenDetail={() => null}
            onSelectAnswer={onSelectAnswer}
            onCollapse={() => null}
            onCollapseTranslation={() => null}
            isDetail
          />
          <Footer
            correctNumber={correctNumber}
            totalNumber={totalNumber}
            onReset={onReset}
            onSubmit={onSubmit}
          />
        </Container>
      </Screen>
    );
  }
  return <Screen canBack title={title} isLoading />;
};

export default Part6Detail;
