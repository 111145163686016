import * as React from 'react'
import { View, Image } from 'react-native'

interface Props {
    uri: string
    width?: number
    height?: number
    style?
}

interface State {
    source: {}
    width: number
    height: number
}

export default class ScaledImage extends React.Component<Props, State> {
    constructor(props) {
        super(props)
        this.state = {
            source: {uri: this.props.uri},
            width: 0,
            height: 0,
        }
    }

    componentWillMount() {
        Image.getSize(this.props.uri, (width, height) => {
            if (this.props.width && !this.props.height) {
                this.setState({
                  source: {uri: this.props.uri},
                  width: this.props.width, 
                  height: height * (this.props.width / width)
                })
            } else if (!this.props.width && this.props.height) {
                this.setState({
                  source: {uri: this.props.uri},
                  width: width * (this.props.height / height), 
                  height: this.props.height
                })
            } else {
                this.setState({
                  source: {uri: this.props.uri},
                  width: width, 
                  height: height
                })
            }
            if (this.props.uri.length == 0) {
                this.setState({
                    width: 0, 
                    height: 0
                })
              }
        }, (error) => {
            console.log("ScaledImage:componentWillMount:Image.getSize failed with error: ", error)
        })
    }

    componentWillUpdate() {
      Image.getSize(this.props.uri, (width, height) => {
          if (this.props.width && !this.props.height) {
              this.setState({
                source: {uri: this.props.uri},
                width: this.props.width, 
                height: height * (this.props.width / width)
              })
          } else if (!this.props.width && this.props.height) {
              this.setState({
                source: {uri: this.props.uri},
                width: width * (this.props.height / height), 
                height: this.props.height
              })
          } else {
              this.setState({
                source: {uri: this.props.uri},
                width: width, 
                height: height
              })
          }
          if (this.props.uri.length == 0) {
            this.setState({
                width: 0, 
                height: 0
            })
          }
      }, (error) => {
          console.log("ScaledImage:componentWillMount:Image.getSize failed with error: ", error)
      })
    }

    render() {
        if (this.props.uri.length == 0) {
            return <View />
        }
    
        return <Image source={this.state.source} style={[this.props.style, {resizeMode: 'contain', alignItems: 'center', height: this.state.height, width: this.state.width}]}/>
    }
}