import React, { FC, memo } from 'react';
import styled from 'styled-components/native';
import Colors from 'theme/colors';
import Fonts from 'theme/fonts';
import Modal from 'modal-react-native-web';

interface Props {
  modalVisible: boolean;
  setModalVisible: () => void;
}

interface StyledProps {
  height?: number;
  width?: number;
  marginHorizontal?: number;
  paddingHorizontal?: number;
  isCorrect?: boolean;
  isSelected?: boolean;
  isShown?: boolean;
  color?: string;
}

const CloseButton = styled.TouchableOpacity`
  background-color: ${Colors.MAIN_COLOR};
  padding: 4px;
  elevation: 2;
  margin-vertical: 12px;
`;

const ButtonText = styled(Fonts.Bold)`
  padding: 4px;
  color: white;
  text-align: left;
`;

const CenteredView = styled.View`
  flex: 1;
  justify-content: center;
  align-items: center;
`;

const ModalView = styled.View`
  background-color: white;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 2px;
  shadow-color: #000;
  shadow-opacity: 0.25;
  shadow-radius: 3.84px;
  elevation: 5;
  padding: 1%;
`;

const TitleText = styled(Fonts.Bold)`
  font-size: 16px;
  background-color: ${Colors.BACKGROUND_COLOR};
  padding: 12px;
`;

const AlertModal: FC<Props> = ({ modalVisible, setModalVisible }) => {

  return (
    <Modal animationType="slide" transparent visible={modalVisible} onBackdropPress={() => setModalVisible()}>
      <CenteredView>
        <ModalView>
          <TitleText>Please select your answer</TitleText>
          <CloseButton onPress={() => setModalVisible()}>
            <ButtonText>Close</ButtonText>
          </CloseButton>
        </ModalView>
      </CenteredView>
    </Modal>
  );
};

export default memo(AlertModal);
