import React, { FC, memo } from 'react';
// import { TouchableOpacity } from 'react-native';
import styled from 'styled-components/native';
import Fonts from 'theme/fonts';
import Colors from 'theme/colors';
import { Answer } from 'constants/AppConstants';
import Divider from 'components/Divider';

interface Props {
  item: any;
  index: number;
  questionNumber: number;
  onClick: (item: any, index: number, selected: string) => void;
}

interface StyledProps {
  width?: number;
  marginHorizontal?: number;
  isCorrect?: boolean;
  isSelected?: boolean;
  isShown?: boolean;
}

const Text = styled(Fonts.Normal)`
  font-size: 18px;
  padding: 8px;
`;

const ItemContainer = styled.View<Pick<StyledProps, 'width'>>`
  width: 100%;
  background-color: ${Colors.SECOND_COLOR};
`;

const ButtonText = styled(Fonts.Normal)<StyledProps>`
  font-size: 18px;
  color: ${props => props.isSelected ? 'green' : 'black'};
  background-color: ${props => props.isShown ? Colors.CORRECT : Colors.SECOND_COLOR}};
  padding: 8px;
`;

const QuestionItem: FC<Props> = ({ item, index, questionNumber, onClick }) => {
  return (
    <ItemContainer>
      <Text >{`${questionNumber}/ ${item.question || ''}`}</Text>
      <Divider />
      {/* <TouchableOpacity onPress={() => onClick(item, index, Answer.A)}> */}
        <ButtonText onPress={() => onClick(item, index, Answer.A)} isSelected={Answer.A == item.selected} isShown={item.submitted && Answer.A == item.correct}>{`A. ${item.a}`}</ButtonText>
      {/* </TouchableOpacity> */}
      <Divider />
      {/* <TouchableOpacity onPress={() => onClick(item, index, Answer.B)}> */}
        <ButtonText onPress={() => onClick(item, index, Answer.B)} isSelected={Answer.B == item.selected} isShown={item.submitted && Answer.B == item.correct}>{`B. ${item.b}`}</ButtonText>
      {/* </TouchableOpacity> */}
      <Divider />
      {/* <TouchableOpacity onPress={() => onClick(item, index, Answer.C)}> */}
        <ButtonText onPress={() => onClick(item, index, Answer.C)} isSelected={Answer.C == item.selected} isShown={item.submitted && Answer.C == item.correct}>{`C. ${item.c}`}</ButtonText>
      {/* </TouchableOpacity> */}
      <Divider />
      {/* <TouchableOpacity onPress={() => onClick(item, index, Answer.D)}> */}
        <ButtonText onPress={() => onClick(item, index, Answer.D)} isSelected={Answer.D == item.selected} isShown={item.submitted && Answer.D == item.correct}>{`D. ${item.d}`}</ButtonText>
      {/* </TouchableOpacity> */}
      <Divider />
  </ItemContainer>
  );
};

export default memo(QuestionItem);
