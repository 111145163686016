import React, { FC, useState, useEffect } from "react";
import { FlatList } from "react-native";
import styled from "styled-components/native";
import Fonts from "theme/fonts";
import Colors from "theme/colors";
import QuestionItem from "./QuestionItem";
import CountDown from "./CountDown";
import TimeText from "./TimeText";
import RawHTML from "components/RawHTML";
import AudioPlayer from "components/AudioPlayer";
import ScaledImage from 'components/ScaledImage';
import InputMultiLine from 'components/InputMultiLine';
import useWindowDimensions from "hooks/useWindowDimensions";
import { IoMdOpen } from "react-icons/io";
import { FaRegKeyboard } from "react-icons/fa";
import SecondaryButton from "components/buttons/SecondaryButton";
import { convertHTMLToPlainText } from 'utils/functions';



interface Props {
  data: any;
  isShownTranslate: boolean;
  part4Index: number;
  testNumber: number;
  isDetail?: boolean;
  onOpenDetail: (data: any, part4Index: number, testNumber: number) => void;
  onOpenTyping?: (data: any, part4Index: number, testNumber: number) => void;
  onSelectAnswer: (
    detailItem: any,
    questionPosition: number,
    selected: string,
    part4Index: number
  ) => void;
  onCollapse: (part4Index: number) => void;
  onCollapseTranslation: (part4Index: number) => void;
}

interface StyledProps {
  size?: number;
  height?: number;
  width?: number;
  marginHorizontal?: number;
  paddingHorizontal?: number;
  paddingRight?: number;
  isCorrect?: boolean;
  isSelected?: boolean;
  isShown?: boolean;
}

interface ItemType {
  id: number;
  a: string;
  b: string;
  c: string;
  d: string;
  correct: string;
  question: string;
  selected?: string;
  submitted?: boolean;
}

const ItemContainer = styled.View<StyledProps>`
  width: 100%;
  background-color: ${Colors.SECOND_COLOR};
`;

const HorizontalView = styled.View`
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const Text = styled(Fonts.Bold)`
  font-size: 18px;
  padding: 8px;
  flex: 1;
`;

const Button = styled.TouchableOpacity`
  padding: 10px;
`;

const TestFlatList = styled(FlatList as new () => FlatList)`
  width: 100%;
  height: 100%;
`;

const ListContainer = styled.View`
  height: 100%;
  width: 100%;
  padding-vertical: 8px;
`;

const Space = styled.View<StyledProps>`
  background-color: ${Colors.MAIN_COLOR};
  width: 100%;
  height: 1px;
`;

const Divider = styled.View<StyledProps>`
  background-color: ${Colors.MAIN_COLOR};
  width: 100%;
  height: 2px;
  margin-top: 8px;
  margin-bottom: 8px;
`;

const CountDownContainer = styled.View`
  padding-horizontal: 12px;
  padding-vertical: 8px;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

const PlainTextContainer = styled.View`
  padding-horizontal: 12px;
  padding-vertical: 8px;
`;

const HTMLContainer = styled.View`
  padding-horizontal: 12px;
  padding-vertical: 8px;
`;

const Center = styled.View`
  align-items: center;
  justify-content: center;
  margin-vertical: 4px;
`;

const Part4Item: FC<Props> = ({
  data,
  isShownTranslate,
  part4Index,
  testNumber,
  isDetail,
  onOpenDetail,
  onOpenTyping,
  onSelectAnswer,
  onCollapse,
  onCollapseTranslation,
}) => {
  const html = data.data.html;
  const plainText = convertHTMLToPlainText(html);
  const [typeText, setTypeText] = useState<string>('');
  const [isStartTyping, setIsStartTyping] = useState(false);
  const [isDone, setIsDone] = useState(false);
  const [count, setCount] = useState<number>(0);

  useEffect(() => {
    setCount(0);
    setIsStartTyping(false);
    setIsDone(false);
    setTypeText('');
  }, [testNumber, plainText, part4Index])

  useEffect(() => {
    if (!isDone && isStartTyping) {
      const timer = setInterval(() => {
        setCount(count + 1)
      }, 1000)
      return () => clearInterval(timer)
    }
  }, [isStartTyping, count])

  const onTyping = (text) => {
    if (plainText.startsWith(text)) {
      console.log(text.length);
      if (text.length >= plainText.length) {
        setIsDone(true);
      }
      setTypeText(text);
      if (!isStartTyping) {
        setIsStartTyping(true);
      }
    }
  }

  const onEndTime = (leftTime: string) => {}

  const audioPath = `https://github.com/QuizSystem/ETS2020Audios/blob/master/Test${testNumber}/part4_${
    part4Index + 1
  }.mov?raw=true`;

  const imagePath = `https://raw.githubusercontent.com/QuizSystem/ETS2020Images/master/Test${testNumber}/part4_${
    part4Index + 1
  }.png`;
  const { width, height } = useWindowDimensions();
  //const size = width > height ? height : width;
  const size = width > height ? height * 48 / 100 : width * 68 / 100;

  const selectAnswer = (item: any, index: number, selected: string) => {
    onSelectAnswer(item, index, selected, part4Index);
  };

  const openDetail = () => {
    onOpenDetail(data, part4Index, testNumber);
  };

  const openTyping = () => {
    if (onOpenTyping) {
      onOpenTyping(data, part4Index, testNumber);
    }
  }

  const renderItem = ({ index }: { item: ItemType; index: number }) => {
    const item = data.questions[index];
    return <QuestionItem item={item} index={index} onClick={selectAnswer} />;
  };

  const [isShown, setIsShown] = useState(false);

  useEffect(() => {
    setIsShown(isShownTranslate);
  }, [data, isShownTranslate]);
  const wpm = Math.round((typeText.split(' ').length) / (count / 60));
  const cpm = Math.round(typeText.length / (count / 60));
  const min = Math.floor(count / 60);
  const sec = count % 60;
  const time = `${min}:${sec < 10 ? '0' : ''}${sec}`;
  const timeText = `WPM = ${wpm} - CPM = ${cpm} - Time = ${time}`;
  return (
    <ItemContainer>
      <HorizontalView>
        <Text>{`${part4Index + 1}/ ${data.data.title || ""}`}</Text>
        {!isDetail && (
          <Button onPress={openDetail}>
            <IoMdOpen color={Colors.MAIN_COLOR} size={28} />
          </Button>
        )}
        {!isDetail && (
          <Button onPress={openTyping}>
            <FaRegKeyboard color={Colors.MAIN_COLOR} size={28} />
          </Button>
        )}
      </HorizontalView>
      {audioPath.length > 0 && (
        <AudioPlayer audioPath={audioPath} play={false} isDetail={isDetail} />
      )}
      {imagePath.length > 0 && part4Index >= 8 && (
          <ScaledImage style={{alignSelf: 'center', resizeMode: 'contain', maxWidth: width, marginBottom: 8}} uri={imagePath} height={size} />
      )}
      <PlainTextContainer>
        <InputMultiLine
          placeholder={plainText}
          onChangeText={text => onTyping(text)}
          value={typeText}
        />
      </PlainTextContainer>
      {count > 0 && (
        <CountDownContainer>
          <TimeText text={timeText} />
        </CountDownContainer>
      )}
      {/* {isStartTyping && (
        <CountDownContainer>
          <CountDown
            timeCount={300}
            onEnd={(leftTime) => onEndTime(leftTime)}
          />
        </CountDownContainer>
      )} */}
      <Center>
        <SecondaryButton
          title={isShown ? "Hide Transcript" : "Show Transcript"}
          onPress={() => setIsShown(!isShown)}
        />
      </Center>
      {isShown && (
        <HTMLContainer>
          <RawHTML>{data.data.html}</RawHTML>
        </HTMLContainer>
      )}
      {isShown && <Space />}
      {isShown && (
        <HTMLContainer>
          <RawHTML>{data.translate.value}</RawHTML>
        </HTMLContainer>
      )}
      {isShown && <Space />}
      <ListContainer>
        <TestFlatList
          data={data.questions}
          renderItem={renderItem}
          keyExtractor={(item, index) => `${index}_${item.question}`}
        />
      </ListContainer>
      {!isDetail && 9 > part4Index && <Divider />}
    </ItemContainer>
  );
};

export default Part4Item;
