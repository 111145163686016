import React, { FC } from 'react';
import styled from 'styled-components/native';
import Fonts from 'theme/fonts';
import Colors from 'theme/colors';
import { Answer } from 'constants/AppConstants';
import Divider from 'components/Divider';
import RawHTML from 'components/RawHTML';
import AudioPlayer from 'components/AudioPlayer';
import ScaledImage from 'components/ScaledImage';
import useWindowDimensions from 'hooks/useWindowDimensions';
import { IoMdOpen, IoMdCodeWorking } from "react-icons/io";

interface Props {
  item: any;
  index: number;
  testNumber: number;
  isDetail?: boolean;
  onOpenDetail?: (item: any, index: number) => void;
  onOpenDetailFill?: (item: any, index: number) => void;
  onClick: (item: any, index: number, selected: string) => void;
}

interface StyledProps {
  size?: number;
  width?: number;
  height?: number;
  marginHorizontal?: number;
  paddingHorizontal?: number;
  paddingRight?: number;
  isCorrect?: boolean;
  isSelected?: boolean;
  isShown?: boolean;
}

const HorizontalView = styled.View`
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const Text = styled(Fonts.Bold)`
  font-size: 18px;
  padding: 8px;
  flex: 1;
`;

const Button = styled.TouchableOpacity`
  padding: 10px;
`;

const ScrollView = styled.ScrollView`
  padding-horizontal: 4px;
`;

const ItemContainer = styled.View<StyledProps>`
  width: 100%;
  background-color: ${Colors.SECOND_COLOR};
`;

const ButtonText = styled(Fonts.Normal) <StyledProps>`
  font-size: 18px;
  color: ${props => props.isSelected ? 'green' : 'black'};
  background-color: ${props => props.isShown ? Colors.CORRECT : Colors.SECOND_COLOR};
  padding: 8px;
`;

const HTMLContainer = styled.View`
  padding-horizontal: 12px;
  padding-vertical: 8px;
`;

const Part1Item: FC<Props> = ({ item, index, testNumber, isDetail, onOpenDetail, onOpenDetailFill, onClick }) => {
  const openDetail = () => {
    onOpenDetail && onOpenDetail(item, index);
  };

  const openDetailFill = () => {
    onOpenDetailFill && onOpenDetailFill(item, index);
  };

  const audioPath = `https://github.com/QuizSystem/ETS2020Audios/blob/master/Test${testNumber}/part1_${index + 1}.mov?raw=true`;

  const imagePath = `https://raw.githubusercontent.com/QuizSystem/ETS2020Images/master/Test${testNumber}/part1_${index + 1}.png`;
  const { width, height } = useWindowDimensions();
  const size = width > height ? height * 48 / 100 : width * 68 / 100;

  return (
    <ItemContainer>
      <ScrollView>
        <HorizontalView>
          <Text>{`${index + 1}/`}</Text>
          {!isDetail && (
            <Button onPress={openDetail}>
              <IoMdOpen color={Colors.MAIN_COLOR} size={28} />
            </Button>
          )}
          {!isDetail && (
            <Button onPress={openDetailFill}>
              <IoMdCodeWorking color={Colors.MAIN_COLOR} size={28} />
            </Button>
          )}
        </HorizontalView>
        {audioPath.length > 0 && <AudioPlayer audioPath={audioPath} play={false} isDetail={isDetail} />}
        {imagePath.length > 0 && (
          <ScaledImage style={{alignSelf: 'center', resizeMode: 'contain', maxWidth: width}} uri={imagePath} height={size} />
        )}
        <Divider />
        {/* <TouchableOpacity onPress={() => onClick(item, index, Answer.A)}> */}
          <ButtonText onPress={() => onClick(item, index, Answer.A)} isSelected={Answer.A == item.selected} isShown={item.submitted && Answer.A == item.correct}>{`A. ${item.submitted ? item.a : ''}`}</ButtonText>
        {/* </TouchableOpacity> */}
        <Divider />
        {/* <TouchableOpacity onPress={() => onClick(item, index, Answer.B)}> */}
          <ButtonText onPress={() => onClick(item, index, Answer.B)} isSelected={Answer.B == item.selected} isShown={item.submitted && Answer.B == item.correct}>{`B. ${item.submitted ? item.b : ''}`}</ButtonText>
        {/* </TouchableOpacity> */}
        <Divider />
        {/* <TouchableOpacity onPress={() => onClick(item, index, Answer.C)}> */}
          <ButtonText onPress={() => onClick(item, index, Answer.C)} isSelected={Answer.C == item.selected} isShown={item.submitted && Answer.C == item.correct}>{`C. ${item.submitted ? item.c : ''}`}</ButtonText>
        {/* </TouchableOpacity> */}
        <Divider />
        {/* <TouchableOpacity onPress={() => onClick(item, index, Answer.D)}> */}
          <ButtonText onPress={() => onClick(item, index, Answer.D)} isSelected={Answer.D == item.selected} isShown={item.submitted && Answer.D == item.correct}>{`D. ${item.submitted ? item.d : ''}`}</ButtonText>
        {/* </TouchableOpacity> */}
        <Divider />
        {item.submitted && item.html && (
          <HTMLContainer>
            <RawHTML>{item.html}</RawHTML>
          </HTMLContainer>
        )}
      </ScrollView>
    </ItemContainer>
  );
};

export default Part1Item;
