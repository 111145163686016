export const openLinkCurrentTab = link => {
  const prefix1 = 'http://';
  const prefix2 = 'https://';
  console.log(link);
  if (link.startsWith(prefix1) || link.startsWith(prefix2)) {
    window.open(link, "_self", "");
  };
};

export const openLinkNewTab = link => {
  const prefix1 = 'http://';
  const prefix2 = 'https://';
  console.log(link);
  if (link.startsWith(prefix1) || link.startsWith(prefix2)) {
    window.open(link, "_blank", "");
  };
};
