import Screen from '../Screen';
import React, { FC, useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import textAPI from "api/textAPI";
import CryptoJS from 'crypto-js';
import Part7Item from './shared/Part7Item';
import Footer from 'components/Footer';
import { getPart } from 'store/selectors/selectors';
import { updatePartAction } from 'store/actionTypes/actionType';
import { useDispatch, useSelector } from 'react-redux';
import { TestFlatList } from 'components/TestFlatList';

const Part7: FC = () => {
  const [testList, setTestList] = useState<any[]>([]);
  const [correctNumber, setCorrectNumber] = useState<number>(-1);
  const [totalNumber, setTotalNumber] = useState<number>(-1);

  const history = useHistory();
  const dispatch = useDispatch();
  const { testNumber, year } = useParams();
  const testName = `test${testNumber}`;
  const part7  = useSelector(getPart(testName, 'part7'));
  useEffect(() => {
    getData(testNumber, part7);
  }, []);

  const getData = async (testNumber: number, part7: any) => {
    const itemTypes: any[] = (part7 && part7.questions && part7.questions.length > 0) ? part7.questions : [];
    if (itemTypes.length > 0) {
      setTestList(itemTypes);
    } else {
      const data = await textAPI.get(testNumber, 7);
      const plainData = CryptoJS.AES.decrypt(data, 'thieumao').toString(CryptoJS.enc.Utf8);
      const json = JSON.parse(plainData);
      setTestList(json);
      updateDatabase(testName, json);
    }
  };

  const updateDatabase = async (testName: string, part7Questions: any[]) => {
    const part7Detail = {
      name: 'Part 7',
      questions: part7Questions,
    };
    dispatch(updatePartAction(testName, 'part7', part7Detail));
  };

  const onReset = () => {
    let arr = testList || [];
    for (let test of arr) {
      test.isShownTranslate = false;
      const questions = (test.questions) ? test.questions : [];
      for (const question of questions) {
        const item = question;
        if (item) {
          item.submitted = false;
          item.selected = false;
        }
      }
    }
    updateDatabase(testName, arr);

    setCorrectNumber(-1);
  };

  const onSubmit = () => {
    calculateScore();
    let arr = testList || [];
    for (let test of arr) {
      const questions = (test.questions) ? test.questions : [];
      for (const question of questions) {
        const item = question;
        if (item && item.selected) {
          test.isShownTranslate = true;
          item.submitted = true;
        }
      }
    }
    updateDatabase(testName, arr);
  };

  const calculateScore = () => {
    let wrongCount = 0;
    let correctCount = 0;
    let count = 0;
    for (const test of testList) {
      const questions = (test.questions) ? test.questions : []; 
      let allNone = true;
      let allCorrect = true;
      for (const question of questions) {
        count += 1;
        const item = question;
        if (item && item.selected) {
          if (item.selected == item.correct) {
            correctCount += 1;
            allNone = false;
          } else {
            wrongCount += 1;
            allCorrect = false;
            allNone = false;
          }
        } else {
          allCorrect = false;
        }
      }
    }
    setCorrectNumber(correctCount);
    setTotalNumber(count);
  };

  const onOpenDetail = (data: any, part7Index: number) => {
    history.push(`/toeic${year || '2020'}/test${testNumber}/part7/question${part7Index + 1}`);
  };

  const onSelectAnswer = (detailItem: any, questionPosition: number, selected: string, part7Index: number) => {
    let arr = testList || [];
    if (arr[part7Index].questions[questionPosition].selected != selected) {
      arr[part7Index].questions[questionPosition].selected = selected;
    } else {
      arr[part7Index].questions[questionPosition].selected = undefined;
    }
    updateDatabase(testName, arr);
  };

  const renderItem = ({ index }: { item: any; index: number }) => {
    const item = testList[index];
    return (
      <Part7Item
        data={item}
        part7Index={index}
        onOpenDetail={onOpenDetail}
        onSelectAnswer={onSelectAnswer}
        onCollapse={() => null}
        onCollapseTranslation={() => null}
      />
    );
  };

  return (
      <Screen canBack title={`Test ${testNumber} - Part 7`} showMenu={false} showScrollToTop showScrollToBottom isLoading={!testList.length}>
        <TestFlatList
          data={testList}
          renderItem={renderItem}
          keyExtractor={(item, index) => `${index}_${item}`}
        />
        <Footer correctNumber={correctNumber} totalNumber={totalNumber} onReset={onReset} onSubmit={onSubmit} />
      </Screen>
  );
};

export default Part7;
