import React, { FC, memo } from 'react';
import { FlatList } from 'react-native';
import styled from 'styled-components/native';
import Fonts from 'theme/fonts';
import Colors from 'theme/colors';
import QuestionItem from './QuestionItem';
import RawHTML from 'components/RawHTML';
import { IoMdOpen } from "react-icons/io";

interface Props {
  data: any;
  part6Index: number;
  isDetail?: boolean;
  onOpenDetail: (data: any, part6Index: number) => void;
  onSelectAnswer: (detailItem: any, questionPosition: number, selected: string, part6Index: number) => void;
  onCollapse: (part6Index: number) => void;
  onCollapseTranslation: (part6Index: number) => void;
}

interface StyledProps {
  height?: number;
  width?: number;
  marginHorizontal?: number;
  paddingHorizontal?: number;
  paddingRight?: number;
  isCorrect?: boolean;
  isSelected?: boolean;
  isShown?: boolean;
}

interface ItemType {
  id: number;
  a: string;
  b: string;
  c: string;
  d: string;
  correct: string;
  question: string;
  selected?: string;
  submitted?: boolean;
}

const ItemContainer = styled.View<StyledProps>`
  width: 100%;
  background-color: ${Colors.SECOND_COLOR};
`;

const HorizontalView = styled.View`
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const Text = styled(Fonts.Bold)`
  font-size: 18px;
  padding: 8px;
  flex: 1;
`;

const Button = styled.TouchableOpacity`
  padding: 10px;
`;

const TestFlatList = styled(FlatList as new () => FlatList)`
  width: 100%;
  height: 100%;
`;

const ListContainer = styled.View`
  height: 100%;
  width: 100%;  
  padding-vertical: 8px;
`;

const Space = styled.View<StyledProps>`
  background-color: ${Colors.MAIN_COLOR};
  width: 100%;
  height: 1px;
`;

const Divider = styled.View<StyledProps>`
  background-color: ${Colors.MAIN_COLOR};
  width: 100%;
  height: 2px;
  margin-top: 8px;
  margin-bottom: 8px;
`;

const HTMLContainer = styled.View`
  padding-horizontal: 12px;
  padding-vertical: 8px;
`;

const Part6Item: FC<Props> = ({
  data,
  part6Index,
  isDetail,
  onOpenDetail,
  onSelectAnswer,
  onCollapse,
  onCollapseTranslation,
}) => {
  const selectAnswer = (item: any, index: number, selected: string) => {
    onSelectAnswer(item, index, selected, part6Index);
  };

  const openDetail = () => {
    onOpenDetail(data, part6Index);
  };

  const renderItem = ({ index }: { item: ItemType; index: number }) => {
    const item = data.questions[index];
    const questionNumber = index + part6Index * 4 + 130 + 1;
    return (
      <QuestionItem
        item={item}
        index={index}
        questionNumber={questionNumber}
        onClick={selectAnswer}
      />
    );
  };

  return (
    <ItemContainer>
        <HorizontalView>
          <Text>{`${part6Index + 1}/ ${data.data.title || ""}`}</Text>
          {!isDetail && (
            <Button onPress={openDetail}>
              <IoMdOpen color={Colors.MAIN_COLOR} size={28} />
            </Button>
          )}
        </HorizontalView>
        {!data.isCollapsed && (
          <HTMLContainer>
            <RawHTML>{data.data.html}</RawHTML>
          </HTMLContainer>
        )}
        {data.isShownTranslate && <Space />}
        {data.isShownTranslate && !data.isCollapsedTranslation && (
          <HTMLContainer>
            <RawHTML>{data.translate.value}</RawHTML>
          </HTMLContainer>
        )}
        <Space />
        <ListContainer>
          <TestFlatList
            data={data.questions}
            renderItem={renderItem}
            keyExtractor={(item, index) => `${index}_${item.question}`}
          />
        </ListContainer>
        {!isDetail && 3 > part6Index && <Divider />}
    </ItemContainer>
  );
};

export default memo(Part6Item);
