import React, { FC, useState, useEffect } from "react";
import styled from "styled-components/native";
import { Helmet } from "react-helmet";
import HeaderWithTitle from "components/HeaderWithTitle";
import HeaderWithBackForward from "components/HeaderWithBackForward";
import { useHistory, useParams } from "react-router-dom";
import QuestionListModal from "components/QuestionListModal";
import { IoMdArrowRoundUp, IoMdArrowRoundDown } from "react-icons/io";
import Colors from "theme/colors";
import useWindowDimensions from "hooks/useWindowDimensions";
import { scrollToTop, scrollToBottom, onScroll } from "utils/scroll";
import Fonts from "theme/fonts";
import { IoMdHome, IoIosArrowForward } from "react-icons/io";
import BeatLoader from "react-spinners/BeatLoader";
import { css } from "@emotion/core";

interface StyledProps {
  size?: number;
  height?: number;
  width?: number;
  marginHorizontal?: number;
  paddingHorizontal?: number;
  paddingRight?: number;
  isCorrect?: boolean;
  isSelected?: boolean;
  isShown?: boolean;
  marginTop?: number;
  marginLeft?: number;
}

const Container = styled.View`
  display: flex;
  width: 100%;
  height: 100%;
`;

const FloatingButton = styled.TouchableOpacity<StyledProps>`
  margin-top: ${(props) => props.marginTop}px;
  margin-left: ${(props) => props.marginLeft}px;
  padding: 10px;
  position: absolute;
  position: fixed;
`;

const ListContainer = styled.View<StyledProps>`
  width: ${(props) => props.width}px;
  height: 100%;
  padding-top: 10px;
  align-self: center;
`;

const Navigator = styled.View<StyledProps>`
  width: ${(props) => props.width}px;
  flex-direction: row;
  align-self: center;
  align-items: center;
  justify-content: start;
  margin-top: 24px;
  margin-bottom: 12px;
  margin-left: 3%;
  margin-right: 3%;
`;

const Button = styled.TouchableOpacity`
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const RightButton = styled.TouchableOpacity`
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  flex: 1;
`;

const ButtonText = styled(Fonts.Bold)`
  color: ${Colors.MAIN_COLOR};
  text-align: center;
  font-size: 16px;
  padding-horizontal: 8px;
`;

const overrideLoading = css`
  display: block;
  margin: 0 auto;
`;

interface Props {
  title: string;
  canBack?: boolean;
  showMenu?: boolean;
  disableBack?: boolean;
  disableForward?: boolean;
  showArrow?: boolean;
  showScrollToTop?: boolean;
  showScrollToBottom?: boolean;
  onBackDetail?: () => void;
  onForwardDetail?: () => void;
  isLoading?: boolean;
  // testNumber?: number;
  // partNumber?: number;
  // questionNumber?: number;
}

const Screen: FC<Props> = ({
  children,
  title,
  canBack,
  showMenu,
  showArrow,
  disableBack,
  disableForward,
  onBackDetail,
  onForwardDetail,
  showScrollToTop,
  showScrollToBottom,
  isLoading = false,
  // testNumber,
  // partNumber,
  // questionNumber,
}) => {
  const history = useHistory();
  const { testNumber, partNumber, questionNumber, year } = useParams();

  const [isShowMenu, setIsShowMenu] = useState(false);
  const [isHiddenBackToTop, setIsHiddenBackToTop] = useState(true);
  const [isHiddenBackToBottom, setIsHiddenBackToBottom] = useState(false);
  const onShowMenu = () => {
    setIsShowMenu(true);
  };

  const { width, height } = useWindowDimensions();

  const goETS2021Home = () => {
    history.push(`/toeic2021`);
  };

  const goHome = () => {
    history.push(`/toeic${year || '2020'}`);
  };

  const goTest = () => {
    if (testNumber) {
      history.push(`/toeic${year || '2020'}/test${testNumber}`);
    }
  };

  const goPart = () => {
    if (testNumber && partNumber) {
      history.push(`/toeic${year || '2020'}/test${testNumber}/part${partNumber}`);
    }
  };

  const onScrollToTop = () => {
    setIsHiddenBackToTop(true);
    setIsHiddenBackToBottom(false);
    scrollToTop();
  }

  const onScrollToBottom = () => {
    setIsHiddenBackToTop(false);
    setIsHiddenBackToBottom(true);
    scrollToBottom();
  }

  const onScrollEvent = (pageYOffset, scrollHeight) => {
    const distance = height * 4 / 100;
    setIsHiddenBackToTop(pageYOffset <= distance);
    setIsHiddenBackToBottom(pageYOffset + height + distance >= scrollHeight);
  }

  useEffect(() => {
    onScroll(
      onScrollEvent
    );
  }, []);

  return (
    <Container>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      {!showArrow && (
        <HeaderWithTitle
          title={title}
          canBack={canBack && width < 520}
          onBack={() => history.goBack()}
          showMenu={showMenu}
          onShowMenu={() => onShowMenu()}
        />
      )}
      {showArrow && (
        <HeaderWithBackForward
          title={title}
          canBack={canBack && width < 520}
          onBack={() => history.goBack()}
          showArrow={showArrow}
          onBackDetail={onBackDetail}
          onForwardDetail={onForwardDetail}
          disableBack={disableBack}
          disableForward={disableForward}
        />
      )}
      <QuestionListModal
        modalVisible={isShowMenu}
        setModalVisible={() => setIsShowMenu(false)}
      />
      {width >= 520 && (
        <Navigator
          width={width < 520 ? (width * 98) / 100 : (width * 80) / 100}
        >
          <Button onPress={goHome}>
            <IoMdHome
              color={Colors.MAIN_COLOR}
              size={18}
              style={{ marginBottom: 4 }}
            />
            <ButtonText>{`TOEIC ${year || 2020}`}</ButtonText>
          </Button>
          {testNumber && testNumber > 0 && (
            <IoIosArrowForward
              color={Colors.BLACK}
              size={18}
              style={{ marginBottom: 0 }}
            />
          )}
          {testNumber && testNumber > 0 && testNumber <= 10 && (
            <Button onPress={goTest}>
              <ButtonText>{`Test ${testNumber}`}</ButtonText>
            </Button>
          )}
          {partNumber && partNumber > 0 && (
            <IoIosArrowForward
              color={Colors.BLACK}
              size={18}
              style={{ marginBottom: 0 }}
            />
          )}
          {partNumber && partNumber > 0 && partNumber <= 7 && (
            <Button onPress={goPart}>
              <ButtonText>{`Part ${partNumber}`}</ButtonText>
            </Button>
          )}
          {questionNumber && questionNumber > 0 && (
            <IoIosArrowForward
              color={Colors.BLACK}
              size={18}
              style={{ marginBottom: 0 }}
            />
          )}
          {questionNumber && questionNumber > 0 && (
            <Button onPress={() => null}>
              <ButtonText>{`Question ${questionNumber}`}</ButtonText>
            </Button>
          )}

          <RightButton onPress={goETS2021Home}>
            {/* <IoMdHome
              color={Colors.MAIN_COLOR}
              size={18}
              style={{ marginBottom: 4 }}
            />
            <ButtonText>ETS 2021</ButtonText> */}
          </RightButton>
        </Navigator>
      )}

      <ListContainer
        width={width < 520 ? (width * 98) / 100 : (width * 80) / 100}
      >
        { isLoading ? (
            <BeatLoader
              css={overrideLoading}
              size={12}
              color={Colors.MAIN_COLOR}
              loading={true}
            />
          ) : (
            children
          )
        }        
      </ListContainer>
      {width >= 520 && showScrollToTop && !isHiddenBackToTop && (
        <FloatingButton
          marginLeft={(width * 90) / 100}
          marginTop={(height * 2) / 100}
          onPress={() => onScrollToTop()}
        >
          <IoMdArrowRoundUp color={Colors.MAIN_COLOR_2} size={28} />
        </FloatingButton>
      )}
      {width >= 520 && showScrollToBottom && !isHiddenBackToBottom && (
        <FloatingButton
          marginLeft={(width * 90) / 100}
          marginTop={(height * 92) / 100}
          onPress={() => onScrollToBottom()}
        >
          <IoMdArrowRoundDown color={Colors.MAIN_COLOR_2} size={28} />
        </FloatingButton>
      )}
    </Container>
  );
};

Screen.defaultProps = {
  canBack: false,
  showMenu: false,
  showScrollToTop: false,
  showScrollToBottom: false,
};

export default Screen;
