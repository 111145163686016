import Screen from "../Screen";
import React, { FC, useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import Part2Item from "containers/Part2/shared/Part2Item";
import { useDispatch, useSelector } from "react-redux";
import textAPI from "api/textAPI";
import CryptoJS from "crypto-js";
import { updatePartAction } from "store/actionTypes/actionType";
import { getPart } from "store/selectors/selectors";
import Footer from "components/Footer";
import styled from "styled-components/native";
import { scrollToTop } from "utils/scroll";
import Colors from 'theme/colors';
import Fonts from 'theme/fonts';
import useEventListener from '@use-it/event-listener';

const Container = styled.View`
  padding-horizontal: 1%;
`;

const MoreView = styled.View`
  height: 10%;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: center
  margin-vertical: 4px;
`;

const EmptyView = styled.View`
  width: 20px;
`;

const Button = styled.TouchableOpacity`
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-vertical: 8px;
`;

const Text = styled(Fonts.Title4)`
  font-weight: bold;
  color: ${Colors.MAIN_COLOR};
  padding-horizontal: 4px;
`;

const Part2Detail: FC = () => {
  const [testList, setTestList] = useState<any[]>([]);
  const [correctNumber, setCorrectNumber] = useState<number>(-1);
  const [totalNumber, setTotalNumber] = useState<number>(-1);

  const history = useHistory();
  const dispatch = useDispatch();
  const { testNumber, questionNumber, year } = useParams();
  const testName = `test${testNumber}`;
  const part2 = useSelector(getPart(testName, "part2"));
  useEffect(() => {
    getData(testNumber, part2);
    scrollToTop();
  }, []);

  const getData = async (testNumber: number, part2: any) => {
    const itemTypes: any[] =
      part2 && part2.questions && part2.questions.length > 0
        ? part2.questions
        : [];
    if (itemTypes.length > 0) {
      setTestList(itemTypes);
    } else {
      const data = await textAPI.get(testNumber, 2);
      const plainData = CryptoJS.AES.decrypt(data, "thieumao").toString(
        CryptoJS.enc.Utf8
      );
      const json = JSON.parse(plainData);
      setTestList(json);
      updateDatabase(testName, json);
    }
  };

  const updateDatabase = async (testName: string, part2Questions: any[]) => {
    const part2Detail = {
      name: "Part 2",
      questions: part2Questions,
    };
    dispatch(updatePartAction(testName, "part2", part2Detail));
  };

  const selectAnswer = (item: any, index: number, selected: string) => {
    let arr = testList || [];
    if (arr[index].selected != selected) {
      arr[index].selected = selected;
    } else {
      arr[index].selected = undefined;
    }
    updateDatabase(testName, arr);
  };

  const onReset = () => {
    let arr = testList || [];
    for (let item of arr) {
      item.submitted = false;
      item.selected = undefined;
    }
    updateDatabase(testName, arr);

    setCorrectNumber(-1);
  };

  const onSubmit = () => {
    calculateScore();
    let arr = testList || [];
    for (let item of arr) {
      if (item.selected) {
        item.submitted = true;
      }
    }
    updateDatabase(testName, arr);
  };

  const calculateScore = () => {
    let wrongCount = 0;
    let correctCount = 0;
    let count = 0;
    for (const item of testList) {
      count += 1;
      if (item.selected) {
        if (item.selected == item.correct) {
          // correct
          correctCount += 1;
        } else {
          // incorrect
          wrongCount += 1;
        }
      } else {
        // not choose
      }
    }
    setCorrectNumber(correctCount);
    setTotalNumber(count);
  };

  const MAX = 25;
  const PART = 2;

  const onBackDetail = () => {
    if (questionNumber > 1 && questionNumber <= MAX) {
      history.replace(
        `/toeic${year || '2020'}/test${testNumber}/part${PART}/question${+questionNumber - 1}`
      );
    }
  };

  const onForwardDetail = () => {
    if (questionNumber >= 1 && questionNumber < MAX) {
      history.replace(
        `/toeic${year || '2020'}/test${testNumber}/part${PART}/question${+questionNumber + 1}`
      );
    }
  };

  const onOpenDetailFill = () => {
    onReset();
    history.replace(`/toeic${year || '2020'}/test${testNumber}/part2/question${questionNumber}/fill`);
  };

  const onOpenDetailSort = () => {
    onReset();
    history.replace(`/toeic${year || '2020'}/test${testNumber}/part2/question${questionNumber}/sort`);
  };

  const handler = ({ key }) => {
    if (key.length === 1) {
      if (key === '<' || key === ',') {
        onBackDetail();
      } else if (key === '>' || key === '.') {
        onForwardDetail();
      }
    }
  }

  useEventListener('keydown', handler);

  const title = `Question ${questionNumber} / ${MAX}`;

  if (testList.length >= questionNumber) {
    const index = questionNumber - 1;
    const item = testList[index];
    return (
      <Screen
        canBack
        title={title}
        showArrow={true}
        onBackDetail={onBackDetail}
        onForwardDetail={onForwardDetail}
        disableBack={questionNumber == 1}
        disableForward={questionNumber == MAX}
      >
        <Container>
          <Part2Item
            item={item}
            index={index}
            testNumber={testNumber}
            onClick={selectAnswer}
            isDetail
          />
          {item.submitted && (
            <MoreView>
              {/* <Button onPress={onOpenDetailFill}>
                <IoMdCodeWorking color={Colors.MAIN_COLOR} size={20} />
                <Text>Fill in the blank</Text>
              </Button>
              <EmptyView />
              <Button onPress={onOpenDetailSort}>
                <IoMdMenu color={Colors.MAIN_COLOR} size={20} />
                <Text>Sort of words</Text>
              </Button> */}
            </MoreView>
          )}
          <Footer
            correctNumber={correctNumber}
            totalNumber={totalNumber}
            onReset={onReset}
            onSubmit={onSubmit}
          />
        </Container>
      </Screen>
    );
  }
  return <Screen canBack title={title} isLoading />;
};

export default Part2Detail;
