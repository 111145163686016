import React, { FC, memo, useState, useEffect } from 'react';
import styled from 'styled-components/native';
import Fonts from 'theme/fonts';
import Colors from 'theme/colors';
import { Answer } from 'constants/AppConstants';
import Divider from 'components/Divider';
import RawHTML from 'components/RawHTML';
import AudioPlayer from 'components/AudioPlayer';
import Input from 'components/Input';
import Space from 'components/Space';
import { IoMdOpen } from "react-icons/io";
import { TiTick, TiTimes } from "react-icons/ti";
import { isCorrect } from "utils/functions";

interface Props {
  item: any;
  index: number;
  testNumber: number;
  isDetail?: boolean;
  onOpenDetail: (item: any, index: number) => void;
  onClick: (item: any, index: number, selected: string) => void;
  questionText: string, 
  aText: string,
  bText: string,
  cText: string,
  questionWord: string,
  aWord: string,
  bWord: string,
  cWord: string,
}

interface StyledProps {
  width?: number;
  height?: number;
  marginHorizontal?: number;
  paddingHorizontal?: number;
  paddingRight?: number;
  isCorrect?: boolean;
  isSelected?: boolean;
  isShown?: boolean;
}

const HorizontalView = styled.View`
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const Text = styled(Fonts.Bold)`
  font-size: 18px;
  padding: 8px;
  flex: 1;
`;

const NormalText = styled(Fonts.Normal)`
  font-size: 18px;
  color: green;
  padding-left: 8px;
`;

const Button = styled.TouchableOpacity`
  padding: 10px;
`;

const ScrollView = styled.ScrollView`
  padding-horizontal: 4px;
`;

const ItemContainer = styled.View<StyledProps>`
  width: 100%;
  background-color: ${Colors.SECOND_COLOR};
`;

const ButtonText = styled(Fonts.Normal) <StyledProps>`
  font-size: 18px;
  color: ${props => props.isSelected ? 'green' : 'black'};
  background-color: ${props => props.isShown ? Colors.CORRECT : Colors.SECOND_COLOR};
  padding: 8px;
  flex: 1;
  min-width: 40%;
`;

const HTMLContainer = styled.View`
  padding-horizontal: 12px;
`;

const Part2ItemFill: FC<Props> = ({ item, index, testNumber, isDetail, onOpenDetail, onClick, questionText, aText, bText, cText, questionWord, aWord, bWord, cWord }) => {
  const openDetail = () => {
    onOpenDetail(item, index);
  };

  const audioPath = `https://github.com/QuizSystem/ETS2020Audios/blob/master/Test${testNumber}/part2_${index + 7}.mov?raw=true`;

  const [questionInput, setQuestionInput] = useState<string>('');
  const [aInput, setAInput] = useState<string>('');
  const [bInput, setBInput] = useState<string>('');
  const [cInput, setCInput] = useState<string>('');

  useEffect(() => {
    setQuestionInput('');
    setAInput('');
    setBInput('');
    setCInput('');
  }, [item, index, testNumber, isDetail, questionText, aText, bText, cText, questionWord, aWord, bWord, cWord]);

  return (
    <ItemContainer>
      <ScrollView>
        <HorizontalView>
          <Text>{`${index + 1 + 6}/ ${item.submitted ? item.question : questionText}`}</Text>
          {!isDetail && (
            <Button onPress={openDetail}>
              <IoMdOpen color={Colors.MAIN_COLOR} size={28} />
            </Button>
          )}
          {item.submitted && isCorrect(questionInput, questionWord) && (
            <>
              <NormalText>{questionWord}</NormalText>
              <TiTick color={Colors.MAIN_COLOR} size={28} />
            </>
          )}
          {item.submitted && !isCorrect(questionInput, questionWord) && (
            <>
              <NormalText>{questionWord}</NormalText>
              <TiTimes color={Colors.RED} size={28} />
            </>
          )}
          <Input
            placeholder={`${index + 1}/ ___`}
            onChangeText={questionInput => setQuestionInput(questionInput)}
            value={questionInput}
          />
        </HorizontalView>
        {audioPath.length > 0 && <AudioPlayer audioPath={audioPath} play={false} isDetail={isDetail} />}
        <Divider />
        {/* <TouchableOpacity onPress={() => onClick(item, index, Answer.A)}> */}
        <HorizontalView>
          <ButtonText onPress={() => onClick(item, index, Answer.A)} isSelected={Answer.A == item.selected} isShown={item.submitted && Answer.A == item.correct}>{`A. ${item.submitted ? item.a : aText}`}</ButtonText>
          {item.submitted && isCorrect(aInput, aWord) && (
            <>
              <NormalText>{aWord}</NormalText>
              <TiTick color={Colors.MAIN_COLOR} size={28} />
            </>
          )}
          {item.submitted && !isCorrect(aInput, aWord) && (
            <>
              <NormalText>{aWord}</NormalText>
              <TiTimes color={Colors.RED} size={28} />
            </>
          )}
          <Input
            placeholder="A. ___"
            onChangeText={text => setAInput(text)}
            value={aInput}
          />
        </HorizontalView>
        {/* </TouchableOpacity> */}
        <Divider />
        {/* <TouchableOpacity onPress={() => onClick(item, index, Answer.B)}> */}
        <HorizontalView>
          <ButtonText onPress={() => onClick(item, index, Answer.B)} isSelected={Answer.B == item.selected} isShown={item.submitted && Answer.B == item.correct}>{`B. ${item.submitted ? item.b : bText}`}</ButtonText>
          {item.submitted && isCorrect(bInput, bWord) && (
            <>
              <NormalText>{bWord}</NormalText>
              <TiTick color={Colors.MAIN_COLOR} size={28} />
            </>
          )}
          {item.submitted && !isCorrect(bInput, bWord) && (
            <>
              <NormalText>{bWord}</NormalText>
              <TiTimes color={Colors.RED} size={28} />
            </>
          )}
          <Input
            placeholder="B. ___"
            onChangeText={text => setBInput(text)}
            value={bInput}
          />
        </HorizontalView>
        {/* </TouchableOpacity> */}
        <Divider />
        {/* <TouchableOpacity onPress={() => onClick(item, index, Answer.C)}> */}
        <HorizontalView>
          <ButtonText onPress={() => onClick(item, index, Answer.C)} isSelected={Answer.C == item.selected} isShown={item.submitted && Answer.C == item.correct}>{`C. ${item.submitted ? item.c : cText}`}</ButtonText>
          {item.submitted && isCorrect(cInput, cWord) && (
            <>
              <NormalText>{cWord}</NormalText>
              <TiTick color={Colors.MAIN_COLOR} size={28} />
            </>
          )}
          {item.submitted && !isCorrect(cInput, cWord) && (
            <>
              <NormalText>{cWord}</NormalText>
              <TiTimes color={Colors.RED} size={28} />
            </>
          )}
          <Input
            placeholder="C. ___"
            onChangeText={text => setCInput(text)}
            value={cInput}
          />
        </HorizontalView>
        {/* </TouchableOpacity> */}
        <Divider />
        {item.submitted && item.html && (
          <HTMLContainer>
            <Space height={32} />
            <RawHTML>{item.html}</RawHTML>
          </HTMLContainer>
        )}
        <Space height={32} />
      </ScrollView>
    </ItemContainer>
  );
};

export default Part2ItemFill;
