/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from 'react';
import Slider from 'react-input-slider';
import styled from 'styled-components/native';
import Colors from 'theme/colors';
import Fonts from 'theme/fonts';
import useEventListener from '@use-it/event-listener';
import { useIsMounted } from 'utils/useIsMounted';
import { IoMdPlay, IoMdPause, IoMdUndo, IoIosRepeat, IoMdArrowDropup, IoMdArrowDropdown } from 'react-icons/io';

const Container = styled.View`
  border-bottom-color: ${Colors.DIVIDER};
  border-bottom-width: 1px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const BoldText = styled(Fonts.Bold)`
  font-size: 14px;
  color: ${Colors.MAIN_COLOR};
  margin: 4px;
`;

const Text = styled(Fonts.Normal)`
  font-size: 12px;
  color: ${Colors.MAIN_COLOR};
  margin: 4px;
`;

const SpeedText = styled(Fonts.Normal)`
  font-size: 14px;
  color: ${Colors.MAIN_COLOR};
  padding: 2px;
  margin: 4px;
`;

const Button = styled.TouchableOpacity`
  padding: 10px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const Button2 = styled.TouchableOpacity`
  justify-content: center;
  align-items: center;
  padding-horizontal: 10px;
`;

const ColumnView = styled.View`
`;

const AudioPlayer = ({ audioPath, play, isDetail = false }) => {
  const audioRef = useRef();
  const [currentTime, setCurrentTime] = useState(0);
  const [maxTime, setMaxTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const [playTime, setPlayTime] = useState(0);
  const [isPlay, setPlay] = useState(false);
  const [isRepeat, setIsRepeat] = useState(false);

  const [counter, setCounter] = useState(0);
  const [isTyping, setIsTyping] = useState(false);
  const isMounted = useIsMounted();
  useEffect(() => {
    setTimeout(() => {
      if (isMounted.current) {
        if (isTyping) {
          if (counter >= 5) {
            setIsTyping(false);
            setCounter(0);
          } else {
            setCounter(counter + 1);
          }
        }
      }
    }, 1000);
  }, [counter, isMounted, isTyping]);

  const handler = ({ key }) => {
    if (!isDetail) {
      return;
    }
    setIsTyping(true);
    console.log(`key = `, key);
    if (key.length === 1) {
      if ('0123456789'.includes(key)) {
        playAt(Number(key));
      } else if (key === 'a' || key === 'A') {
        playAt(10);
      } else if (key === 'b' || key === 'B') {
        playAt(11);
      } else if (key === 'c' || key === 'C') {
        playAt(12);
      } else if (key === 'd' || key === 'D') {
        playAt(13);
      } else if (key === 'e' || key === 'E') {
        playAt(14);
      } else if (key === 'f' || key === 'F') {
        playAt(15);
      } else if (key === 'z' || key === 'Z') {
        onChangeSpeed();
        onReset();
      } else if (key === 'r' || key === 'R') {
        onRepeat();
      } else if (key === '/' || key === '?') {
        onChangeSpeed();
      } else if (key === '<' || key === ',') {
        // Do nothing
      } else if (key === '>' || key === '.') {
        // Do nothing
      } else {
        onPlay();
      }
    } else if (key === 'ArrowUp') {
      onChangeSpeedUp();
    } else if (key === 'ArrowDown') {
      onChangeSpeedDown();
    } else if (key === 'ArrowLeft') {
      onUndo();
    } else if (key === 'ArrowRight') {
      onRedo();
    }
  }
  useEventListener('keydown', handler);

  useEffect(() => {
    let tempMaxTime = Math.round(currentTime);
    if (tempMaxTime > maxTime) {
      setMaxTime(tempMaxTime);
    } else {
      setPlayTime(pre => pre + 1);
      setMaxTime(tempMaxTime);
      if (playTime > 0 && playTime % 20 === 19 && !isTyping) {
        onPlay();
      }
    }
  }, [Math.round(currentTime)]);

  const handleLoadedData = () => {
    setPlay(play);
    if (play) {
      audioRef.current.play();
    } else {
      audioRef.current.pause();
    }
  };

  const onTimeUpdate = () => {
    setCurrentTime(audioRef.current.currentTime || 0);
    setDuration(audioRef.current.duration || 0);
  };

  const list = [0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1.0, 1.1, 1.2, 1.3, 1.4, 1.5]; // 8
  const defaultSpeed = 1.0;
  const defaultIndex = list.findIndex(item => item === defaultSpeed);
  const [speed, setSpeed] = useState(defaultSpeed);
  const [speedIndex, setSpeedIndex] = useState(defaultIndex);

  const onChangeSpeed = () => {
    setSpeedIndex(defaultIndex);
    setSpeed(list[defaultIndex]);
    audioRef.current.playbackRate = list[defaultIndex];
  };

  const onChangeSpeedUp = () => {
    const newIndex = speedIndex + 1 >= list.length ? 0 : speedIndex + 1;
    setSpeedIndex(newIndex);
    setSpeed(list[newIndex]);
    audioRef.current.playbackRate = list[newIndex];
  };

  const onChangeSpeedDown = () => {
    const newIndex = speedIndex - 1 < 0 ? list.length - 1 : speedIndex - 1;
    setSpeedIndex(newIndex);
    setSpeed(list[newIndex]);
    audioRef.current.playbackRate = list[newIndex];
  };

  const onPlay = () => {
    audioRef.current.loop = isRepeat;
    if (isPlay) {
      audioRef.current.pause();
      setPlay(false);
    } else {
      audioRef.current.play();
      setPlay(true);
    }
  };

  const onRedo = () => {
    const undoItem = audioRef.current.currentTime + 1;
    changeSliderValue(undoItem);
    setPlay(true);
    audioRef.current.play();
  };

  const onUndo = () => {
    const undoItem = audioRef.current.currentTime - 1;
    changeSliderValue(undoItem);
    setPlay(true);
    audioRef.current.play();
  };

  const onRepeat = () => {
    setIsRepeat(!isRepeat);
    if (!isRepeat && !isPlay) {
      setPlay(true);
      audioRef.current.play();
    }
  }

  const onReset = () => {
    playAt(0);
  }

  const playAt = (x) => {
    setCurrentTime(x);
    audioRef.current.currentTime = x;
  }

  const changeSliderValue = (sliderValue) => {
    audioRef.current.pause();
    // player.seek(sliderValue);
    setCurrentTime(sliderValue);
    audioRef.current.currentTime = sliderValue;
  }

  const onDragEnd = () => {
    if (isPlay) {
      audioRef.current.play();
    }
  }

  return (
    <Container>
      {!isPlay && (
        <Button onPress={onPlay}>
          <IoMdPlay color={Colors.MAIN_COLOR} size={20} />
        </Button>
      )}
      {isPlay && (
        <Button onPress={onPlay}>
          <IoMdPause color={Colors.MAIN_COLOR} size={20} />
        </Button>
      )}
      <Text>{`${Math.round(currentTime)}`}</Text>
      <audio
          style={{ marginHorizontal: 16, paddingHorizontal: 16 }}
          ref={audioRef}
          src={audioPath}
          onLoadedData={() => handleLoadedData()}
          onTimeUpdate={() => onTimeUpdate()}
          onEnded={() => setPlay(false)}
          loop={isRepeat}
        />
      <Slider
        style={{ flex: 1, height: 12, margin: 8 }}
        xmin={0}
        xmax={duration}
        axis="x"
        x={currentTime}
        onChange={({ x }) => changeSliderValue(x)}
        onDragEnd={() => onDragEnd()}
      />
      <Text>{`${Math.round(duration)}`}</Text>
      <ColumnView>
        <Button2 onPress={onChangeSpeedUp}>
          <IoMdArrowDropup color={Colors.MAIN_COLOR} size={20} />
        </Button2>
        <Button2 onPress={onChangeSpeed}>
          <SpeedText>{`${speed === 1 ? '1.0' : speed}`}</SpeedText>
        </Button2>
        <Button2 onPress={onChangeSpeedDown}>
          <IoMdArrowDropdown color={Colors.MAIN_COLOR} size={20} />
        </Button2>
      </ColumnView>
      <Button onPress={onUndo}>
        <IoMdUndo color={Colors.MAIN_COLOR} size={20} />
      </Button>
      <Button onPress={onRepeat}>
        <IoIosRepeat color={Colors.MAIN_COLOR} size={20} />
        {isRepeat && <BoldText>{playTime} times</BoldText>}
      </Button>
    </Container>
  );
};

export default AudioPlayer;
