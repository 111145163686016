import Screen from '../Screen';
import React, { FC, useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import textAPI from 'api/textAPI';
import CryptoJS from 'crypto-js';
import Part5Item from './shared/Part5Item';
import Footer from 'components/Footer';
import { getPart } from 'store/selectors/selectors';
import { updatePartAction } from 'store/actionTypes/actionType';
import { useDispatch, useSelector } from 'react-redux';
import { TestFlatList } from 'components/TestFlatList';

const Part5: FC = () => {
  const [testList, setTestList] = useState<any[]>([]);
  const [correctNumber, setCorrectNumber] = useState<number>(-1);
  const [totalNumber, setTotalNumber] = useState<number>(-1);

  const history = useHistory();
  const dispatch = useDispatch();
  const { testNumber, year } = useParams();
  const testName = `test${testNumber}`;
  const part5  = useSelector(getPart(testName, 'part5'));
  useEffect(() => {
    getData(testNumber, part5);
  }, []);

  const getData = async (testNumber: number, part5: any) => {
    const itemTypes: any[] = (part5 && part5.questions && part5.questions.length > 0) ? part5.questions : [];
    if (itemTypes.length > 0) {
      setTestList(itemTypes);
    } else {
      const data = await textAPI.get(testNumber, 5);
      const plainData = CryptoJS.AES.decrypt(data, 'thieumao').toString(CryptoJS.enc.Utf8);
      const json = JSON.parse(plainData);
      setTestList(json);
      updateDatabase(testName, json);
    }
  };

  const updateDatabase = async (testName: string, part5Questions: any[]) => {
    const part5Detail = {
      name: 'Part 5',
      questions: part5Questions,
    };
    dispatch(updatePartAction(testName, 'part5', part5Detail));
  };

  const selectAnswer = (item: any, index: number, selected: string) => {
    let arr = testList || [];
    if (arr[index].selected != selected) {
      arr[index].selected = selected;
    } else {
      arr[index].selected = undefined;
    }
    updateDatabase(testName, arr);
  };

  const onReset = () => {
    let arr = testList || [];
    for (let item of arr) {
      item.submitted = false;
      item.selected = undefined;
    }
    updateDatabase(testName, arr);

    setCorrectNumber(-1);
  };

  const onSubmit = () => {
    calculateScore();
    let arr = testList || [];
    for (let item of arr) {
      if (item.selected) {
        item.submitted = true;
      }
    }
    updateDatabase(testName, arr);
  };

  const calculateScore = () => {
    let wrongCount = 0;
    let correctCount = 0;
    let count = 0;
    for (const item of testList) {
      count += 1;
      if (item.selected) {
        if (item.selected == item.correct) {
          // correct
          correctCount += 1;
        } else {
          // incorrect
          wrongCount += 1;
        }
      } else {
        // not choose
      }
    }
    setCorrectNumber(correctCount);
    setTotalNumber(count);
  };

  const onOpenDetail = (item: any, index: number) => {
    history.push(`/toeic${year || '2020'}/test${testNumber}/part5/question${index + 1}`);
  };

  const renderItem = ({ index }: { item; index }) => {
    const item = testList[index];
    return (
      <Part5Item
        item={item}
        index={index}
        onClick={selectAnswer}
        onOpenDetail={onOpenDetail}
      />
    );
  };

  return (
      <Screen canBack title={`Test ${testNumber} - Part 5`} showMenu={false} showScrollToTop showScrollToBottom isLoading={!testList.length}>
        <TestFlatList
          data={testList}
          renderItem={renderItem}
          keyExtractor={(item, index) => `${index}_${item}`}
        />
        <Footer correctNumber={correctNumber} totalNumber={totalNumber} onReset={onReset} onSubmit={onSubmit} />
      </Screen>
  );
};

export default Part5;
